import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PasswordChangeService } from 'src/app/core/services/passwordchange.service';
import { ScriptService } from 'src/app/core/services/script.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-pcs-help',
  // templateUrl: './pcs-help.component.html', //uncomment to use html file to load the page
  template: '<app-header></app-header><div id="acn-alert" [attr.data-widgetid]="widgetID" [attr.data-appid]="notificationframeworkAPPID"></div><div [innerHTML]="content"></div><app-footer></app-footer>', //comment for testing the page
  encapsulation: ViewEncapsulation.None,
  styleUrl: './pcs-help.component.css',
  providers: [ScriptService],
})
export class PCSHelpComponent {
  content : any = "";
  notificationframeworkAPPID: any;
  widgetID: string = '';

  constructor(
    private adminChangePasswordService : PasswordChangeService,
    private sanitizer: DomSanitizer,
    private scriptService: ScriptService,
    private config: AppConfigService
  ){
    const constants = this.config.config['constants'] as any;
    this.notificationframeworkAPPID = constants.NOTIFICATION_FRAMEWORK.APPID;
    this.widgetID = constants.NOTIFICATION_FRAMEWORK['acn-alert'].WIDGET_ID;
    this.content = '<div class="row col-md-12 d-flex justify-content-center mt-5"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
    adminChangePasswordService.HelpPageContent().subscribe({
      next: (data) => {
        this.content  = sanitizer.bypassSecurityTrustHtml(data);
      },
      error: (err) => {
        this.content  = '<div class="row col-md-12 d-flex justify-content-center mt-5">An Error has occured.</div>';
      }
    })
  }
  ngOnInit(){
    this.scriptService.load('alert').then(() => {
      console.log('Notification Framework loaded and initialized in Help Page');
    });
  }
}
