import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AppConfigService } from './core/services/app-config.service';
import { APP_INITIALIZER } from '@angular/core';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { BulkRpwComponent } from './pages/resetpassword/bulk/bulk.rpw.component';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SingleRpwComponent } from './pages/resetpassword/single/single.rpw.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { AcpComponent } from './pages/adminchangepassword/acp.component';
import { AcpUnauthorizedComponent } from './pages/acp-unauthorized/acp-unauthorized.component';
import { RpwUnauthorizedComponent } from './pages/rpw-unauthorized/rpw-unauthorized.component';
import { PCSHelpComponent } from './pages/pcs-help/pcs-help.component';

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        AcpComponent,
        BulkRpwComponent,
        LandingpageComponent,
        SingleRpwComponent,
        AcpUnauthorizedComponent,
        RpwUnauthorizedComponent,
        PCSHelpComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgbModule,
        ReactiveFormsModule,
        RebarAuthModule.forRoot(),
        MsalModule,
        FormsModule,
        NgbTooltipModule], providers: [{
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService) => () => config.load(),
            deps: [AppConfigService], multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        }, {
            provide: Window,
            useValue: window
        },
        MsalGuard,
        MsalService,
        MsalBroadcastService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
