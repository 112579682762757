
<div class="container-fluid bg-purple-gradient">
  <div id="acn-alert" [attr.data-widgetid]="getWidgetID('acn-alert')" [attr.data-appid]="notificationframeworkAPPID"></div>
  <!-- <div id="acn-toast" data-widgetid="20719" data-appid="18919"></div> -->

  <div class="float-end mt-5 me-5">
    <a class="logout-text" (click)="logout()">
      <span>Sign Out &nbsp;</span>
      <i class="bi bi-box-arrow-right"></i>
    </a>
  </div>

  <div class="d-flex align-items-center justify-content-center card text-center w-30 position-absolute top-50 start-50 translate-middle">
    <div class="position-absolute bottom-80 translate-custom">
      <div class="company-logo"></div>
    </div>
    <div class="card custom-card">
      <div class="card-body">
        <div class="row row-title">
          <h2>Password Customer Service</h2>
        </div>

        <div class="container">
          <div class="row custom-row">
            <div class="col-auto">
              <a
                href="AdminChangePW"
                class="d-flex align-items-center link-underline-light"
                id="acsbtn"
              >
                <div class="acp-icon custom-icon"></div>
                <span
                  ><div class="main-text">Admin Change Password</div>
                  <div class="subtitle-text">
                    Change Password for Administrative Accounts
                  </div></span>
              </a>
              <div *ngIf="auth_error" class="text-sm text-center text-danger">
                {{auth_error}}
            </div>
            </div>
          </div>

          <div class="row custom-row">
            <div class="col-auto">
              <a
                href="SingleResetPassword"
                class="d-flex align-items-center link-underline-light"
                id="acsbtn"
              >
                <div class="rpw-icon custom-icon"></div>
                <span
                  ><div class="main-text">Reset Password</div>
                  <div class="subtitle-text">
                    Enterprise Directory Reset Password
                  </div></span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
