import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable,} from 'rxjs';
import { Domains } from '../../models/ApiResponse';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordChangeService {

  baseUrl: string = "";
  constant: any;

  constructor(
    private http: HttpClient,
    private config: AppConfigService
  ) {
    this.constant = (config.config['constants'] as any).ADMIN_CHANGE_PASSWORD;
    this.baseUrl = this.constant.BASE_URL;
  }

  GetDomain$(eid: string) {
    var url = this.baseUrl + this.constant.SERVICE.GET_DOMAIN;
    var params= {
      "eid": eid
    };

    return this.postData$<Domains>(url, params);
  }

  PasswordChangeSubmit$(eid: string, domain: string, password: string){
    var url = this.baseUrl + this.constant.SERVICE.PASSWORD_CHANGE;

    var params = {
      "eid": eid,
      "domain": domain,
      "password": password
    };

    return this.postData$<string>(url, params, {responseType: "text"});
  }

  HelpPageContent(){
    var url = this.baseUrl + this.constant.SERVICE.HELP_PAGE;
    return this.postData$<string>(url, null, {responseType: "text"});
  }

  GuardAuth$(){
    var url = this.baseUrl + this.constant.SERVICE.AUTH_GUARD;
    return this.postData$<string>(url, null, {responseType: "text"});
  }

  postData$<T>(url: string, param: any, options: any = {}): Observable<any> {
    return this.http.post<T>(url, param, options);
  }
}