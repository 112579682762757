import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
export class PasswordValidators {
  static matchingPassword(control: FormControl): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirm_password');

    if (password?.value != "" && confirmPassword?.value != "" && password?.value !== confirmPassword?.value) {
      return { mismatchedPasswords: true };
    }

    return null;
  }

  static atLeastOneUppercaseOrLowercase(control: FormControl): ValidationErrors | null {
    const value = control.value;
    if (isPassphrase(value) || /[A-Z]/.test(value) || /[a-z]/.test(value)) {
      return null;
    }
    return { atLeastOneUppercaseOrLowercase: true };
  }

  static atLeastOneNumber(control: FormControl): ValidationErrors | null {
    const value = control.value;
    if (isPassphrase(value) || /[0-9]/.test(value)) {
      return null;
    }
    return { atLeastOneNumber: true }; 
  }

  static atLeastOneSpecialCharacter(control: FormControl): ValidationErrors | null {
    const value = control.value;
    if (isPassphrase(value) || /[! @#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value)) {
      return null; 
    }
    return { atLeastOneSpecialCharacter: true };
  }

  static derivedFrom(eidName: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const password = control.value;
      const charLimit: number = 4;

      for(let i = 0; i <= eidName.length - charLimit; i++){
        let substring = eidName.substring(i, i + charLimit);
        if (password.includes(substring)){
          return { derivedPassword: true };
        }
      }

      return null;
    };
  }
}

export function isPassphrase(str: string){
  let maxLength = 16;
  return str.length >= maxLength;
}