import {FormControl, ValidationErrors, Validator } from '@angular/forms';
import { AbstractControl, ValidatorFn } from "@angular/forms";

export class AccountValidators {

  static characterValidator(str: string) : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value.indexOf(str) !== -1) {
        return { hasInvalidCharacter: true };
      }
      return null;
    };
  }
  
  static accountIdLimitValidator(control: FormControl): ValidationErrors | null {
    const value = control.value;
    const lines = value.split(/[\n]/)
                        .map(
                          (account: string) => account.trim()
                        ).filter(
                          (account: string) => account != ""
                        );
    if (lines.length > 500) {
      return { accountIdLimit: true };
    }
    return null;
  }

  static userIdContainsAtSymbolValidator(control: FormControl): ValidationErrors | null { 
    const value: string = control.value;

    if (value.includes('@')) {
      return { userIdContainsAtSymbolValidator: true };
    }
  
    return null;
  }
}