import { Component, OnInit,  ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, Validators,ValidationErrors, AbstractControl, FormArray, FormGroup, ValidatorFn, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { PasswordChangeService } from 'src/app/core/services/passwordchange.service';
import { AccountValidators } from 'src/app/utils/validators/accountid.validator';
import { DomainValidators } from 'src/app/utils/validators/domain.validator';
import { PasswordValidators, isPassphrase } from 'src/app/utils/validators/password.validator';
import { AdminPasswordPayload } from 'src/app/models/AdminPasswordPayload';
import { environment } from 'src/environments/environment';
import { ScriptService } from 'src/app/core/services/script.service';
import { WidgetService } from 'src/app/core/services/widgets.service';
import { debounceTime, fromEvent, merge, tap } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';

declare const acnmodal: any;
declare const acntoasts: any;
@Component({
  selector: 'app-acs-landing',
  templateUrl: './acp.component.html',
  styleUrls: ['./acp.component.css'],
  providers: [ScriptService],
})
export class AcpComponent {
  isPassphrase = isPassphrase; //use function isPassphrase in password validator
  changePasswordForm!: FormGroup;
  selectedDomain: string = "";
  showPasswordComplexity: any ="false";

  rebarAuth: string | undefined = "";
  constants: any;
  notificationframeworkAPPID: any;
  domainConstant: any;

  submitted: boolean = false;
  submit: any = false;

  passwordClicked: boolean = false;
  errormessage: string ="";
  formb: FormBuilder | undefined;
  disabled: boolean =true;
  accountIdCharacterCount: boolean = false;
  loading: boolean = false;
  loadingSubmit: boolean = false;
  isAccountUnknown: boolean = false;
  errorCounter: number = 0;
  response: boolean = false;
  acpEmailFailed: any = "";
  isAuthorized: boolean = false;

  environment: string = "";

  @ViewChild('passwordInput') passwordInput!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private rebarAuthService:RebarAuthService,
    private passwordChangeService: PasswordChangeService,
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer,
    private window: Window,
    private scriptService: ScriptService,
    private widgetService: WidgetService,
    private config: AppConfigService
  ) {
    this.environment = (this.config.config['constants'] as any).environment;
    this.constants = (this.config.config['constants'] as any).ADMIN_CHANGE_PASSWORD.constants;
    this.notificationframeworkAPPID = (this.config.config['constants'] as any).NOTIFICATION_FRAMEWORK.APPID;
    this.domainConstant = this.constants.domain;

    this.changePasswordForm = this.fb.group({
      accountId: [
        '', 
        [
          Validators.required,
          AccountValidators.characterValidator('@'), 
          Validators.maxLength(70)
        ]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.atLeastOneUppercaseOrLowercase,
          PasswordValidators.atLeastOneNumber,
          PasswordValidators.atLeastOneSpecialCharacter,
          PasswordValidators.derivedFrom(this.rebarAuthService.getUser()!.split('@')[0])
        ]
      ],
      confirm_password: ['', Validators.required],
      domainDir: [false],
      domainDs: [false]
    },{
      validators: [
        PasswordValidators.matchingPassword,
        DomainValidators.requiredDomain
      ]
    });
  }

  ngOnInit(): void {

    this.initialState();

    let submitStorage = this.localStorageService.key("submit_message");
    if (submitStorage.exists()){
      this.submit = JSON.parse(<string>submitStorage.get());
      this.submit.message = this.sanitizer.bypassSecurityTrustHtml(this.submit.message);
      submitStorage.remove();
    }

    this.scriptService.load('toast','alert','modal').then(() => {
      console.log('Notification Framework loaded and initialized in ACP');
      setTimeout(() => {
        acntoasts.show(this.widgetService.toastSourceID);
        console.log('Toast loaded');
      }, 3000);
    });

    merge(
      fromEvent(window, "mousemove"),
      fromEvent(window, "keypress")
    ).pipe(
      debounceTime(7200000),
      tap((event: any) => {
        if (
          this.changePasswordForm.controls.accountId.value.trim() != "" ||
          this.changePasswordForm.controls.password.value.trim() != "" ||
          this.changePasswordForm.controls.confirm_password.value.trim() != ""
        ){
          console.log('modal loaded');
          acnmodal.show(this.widgetService.modalSourceID);
        }
      })
    ).subscribe();
  }

  onPasswordFocusInput() {
    this.passwordClicked = true;
  }

  onDomainChange(event: any, selectedDomain: string) {
    this.selectedDomain = event.target.checked ? selectedDomain : "";
    this.changePasswordForm.updateValueAndValidity();
    return this.selectedDomain;
  }

  onSubmit() {
    this.submitted = true;
    this.loadingSubmit = true;

    if (this.changePasswordForm.invalid){      
      this.errormessage = "The password does not meet the password policy requirements. Check the minimum password length, password complexity and password history requirements."
      this.isAccountUnknown=true;
      this.loadingSubmit = false;
      return;
    }

    if (confirm(this.constants.compliance_text) === false){
      this.openModal();
      this.clearformSubmit()
      this.loadingSubmit = false;
      return;
    }

    var eid       = this.changePasswordForm.controls.accountId.value!;
    var domain    = this.selectedDomain;
    var password  = this.changePasswordForm.controls.password.value!;

    this.passwordChangeService.PasswordChangeSubmit$(eid, domain, password).subscribe({
      next: (msg) => {
        const parsedData =JSON.parse(msg);
        this.acpEmailFailed =parsedData.errorMessage
        this.localStorageService
            .key("submit_message")
            .set(
              JSON.stringify({
                class: "text-success",
                message: parsedData.responseMessage, 
                failMessage: parsedData.errorMessage
              })
            );
        this.window.location.reload();
      },
      error: (err) => {
        this.submitted = false;
        this.loadingSubmit = false;
        const parsedData =JSON.parse(err.error);   
        let responseMessage = parsedData.responseMessage;   
        this.submit = {
          class: "text-danger",
          message: this.sanitizer.bypassSecurityTrustHtml(responseMessage),
          failMessage: parsedData.errorMessage
        }
      }
    });
  }
  OnEidChange(event: any){

    var eid = event.target.value;
    this.initialState();
    //TODO: Loader while waiting for API Return?

    this.loading = false;

    if (eid!=""){
      if (eid.indexOf(".") !== -1){
        this.loading = true;
        this.submit = {
          class: "",
          message: ""
        };
        this.passwordChangeService.GetDomain$(eid).subscribe({
          next: (data) => {

            if (data.AccountDomains.includes("DIR")){
              this.changePasswordForm.controls.domainDir.enable();
              this.changePasswordForm.controls.domainDs.disable();
              this.selectedDomain = "DIR";
              this.changePasswordForm.controls.domainDir.setValue(true);
              this.changePasswordForm.controls.domainDs?.setValue(false);
            }

            if (data.AccountDomains.includes("DS")){
              this.changePasswordForm.controls.domainDir.disable();
              this.changePasswordForm.controls.domainDs.enable();
              this.selectedDomain = "DS";
              this.changePasswordForm.controls.domainDir?.setValue(false);
              this.changePasswordForm.controls.domainDs.setValue(true);
              if (this.environment == "stage")
              {
                this.changePasswordForm.controls.domainDs.disable();
              }
            }

            if (data.AccountDomains.includes("DIR") && data.AccountDomains.includes("DS")){
              this.changePasswordForm.controls.domainDir.enable();
              this.changePasswordForm.controls.domainDs.enable();
              this.selectedDomain = "";
              this.changePasswordForm.controls.domainDir?.setValue(false);
              this.changePasswordForm.controls.domainDs?.setValue(false);
            }

            this.changePasswordForm.updateValueAndValidity();
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.submit = {
              class: "text-danger",
              message: err.error.CheckEmployee
            }
          }
        });
      }
    }
  }

  initialState(): void{
    this.selectedDomain = "";
    this.changePasswordForm.controls.domainDir.disable();

    if (this.environment == "stage"){
      this.changePasswordForm.controls.domainDs.enable();
      this.changePasswordForm.controls.domainDs.setValue(true);
      this.changePasswordForm.controls.domainDs.disable();
      this.selectedDomain = "DS";
    }else{
      this.changePasswordForm.controls.domainDs.disable();
    }
  }

  getWidgetID(id: string): string {
    const constant = this.config.config['constants'] as any;
    const NOTIFICATION_FRAMEWORK = constant.NOTIFICATION_FRAMEWORK;
    return NOTIFICATION_FRAMEWORK[id].WIDGET_ID;
  }

  ngAfterViewInit() {
  
  }

  ngPasswordLeave()
  {
    console.log("LOG: left password");
    if(this.changePasswordForm.controls.password.value === '' || (!this.changePasswordForm.controls.password.errors)){
      this.passwordClicked = false;
    }
  }

  openModal(){
    const modelDiv = document.getElementById('myModal');
    if (modelDiv!=null){
      modelDiv.style.display = 'block';
    }
  }

  closeModal(){
    const modelDiv = document.getElementById('myModal');
    if (modelDiv!=null){
      modelDiv.style.display = 'none';
    }
  }
  clearformSubmit(){
    this.changePasswordForm.controls.accountId.setValue('');
    this.changePasswordForm.controls.password.setValue('');
    this.changePasswordForm.controls.confirm_password.setValue('');
    this.selectedDomain = "";
    this.changePasswordForm.controls.domainDir.disable();
    if (this.environment == "stage"){
      this.changePasswordForm.controls.domainDir.disable();
      this.changePasswordForm.controls.domainDs.enable();
      this.changePasswordForm.controls.domainDs.setValue(true);
      this.selectedDomain = "DS";
    }else{
      this.changePasswordForm.controls.domainDs.disable();
    }
    this.submitted = false;
    this.passwordClicked = false;
  }
  error(){
    if(this.isAccountUnknown==true){
      this.errorCounter++
    }
    if(this.errorCounter!=0){
      this.response=true
    }
  }
}

