import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './core/services/localstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor( 
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void { 
  }

  ngOnDestroy(){
  }  
}
