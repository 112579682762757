import { AbstractControl, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export class DomainValidators {

    static requiredDomain(control: AbstractControl) : ValidationErrors | null {
        const domainDir = control.get('domainDir');
        const domainDs = control.get('domainDs');
        if (domainDir?.value == false && domainDs?.value == false){
            return {requiredDomain:true};
        }
        return null;
    }
    static clearErrors(control: AbstractControl) : void {
        control.get('domainDir')?.setValue(false);
        control.get('domainDs')?.setValue(false);
    }
}