import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Domains } from "src/app/models/ApiResponse";
import { ResetPasswordPayload } from "src/app/models/ResetPasswordPayload";
import { AppConfigService } from "./app-config.service";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  baseUrl: string = "";
  constant: any;

  constructor(
    private http: HttpClient,
    private config: AppConfigService
  ) {
    this.constant = (this.config.config['constants'] as any).RESET_PASSWORD;
    this.baseUrl = this.constant.BASE_URL;
  }

  GetDomain$(eid: string) {
    var url = this.baseUrl + this.constant.SERVICE.GET_DOMAIN;
    var params= {
      "eid": eid
    };
    return this.postData$<Domains>(url, params);
  }

  GuardAuth$(functionClassifier: string){
    var url = this.baseUrl + this.constant.SERVICE.AUTH_GUARD;
    var params = {
      "function": functionClassifier
    }
    return this.postData$<string>(url, params, {responseType: "text"});
  }

  ResetPasswordSubmit$(payload: ResetPasswordPayload){
    var url = this.baseUrl + this.constant.SERVICE.RESET_PASSWORD;
    return this.postData$<string>(url, payload, {responseType: "text"});
  }

  EnableAccountInAD$(payload: ResetPasswordPayload){
    var url = this.baseUrl + this.constant.SERVICE.ENABLE_IN_AD;
    return this.postData$<string>(url, payload, {responseType: "text"});
  }

  EnableAccountOnPremInAD$(payload: ResetPasswordPayload){
    var url = this.baseUrl + this.constant.SERVICE.ENABLE_ONPREM_IN_AD;
    return this.postData$<string>(url, payload, {responseType: "text"});
  }

  ForceChangePWNextLogon$(payload: ResetPasswordPayload){
    var url = this.baseUrl + this.constant.SERVICE.FORCE_CHANGE_PW_NEXT_LOGON;
    return this.postData$<string>(url, payload, {responseType: "text"});
  }

  BulkResetPassword$(eids: string[], domain: string, forceChangePasswordNextSignIn: boolean, forceReason: string, snowTicket: string){
    var url = this.baseUrl + this.constant.SERVICE.BULK_RESET;
    var params = {
      "eids": eids,
      "domain": domain,
      "forceChangePasswordNextSignIn": forceChangePasswordNextSignIn,
      "forceReason": forceReason,
      "snowTicket": snowTicket
    };
    return this.postData$<string>(url, params, {responseType: "text"});
  }

  CheckComplianceFlag$(eid: string, domain: string){
    var url = this.baseUrl + this.constant.SERVICE.VALIDATE_COMPLIANCE_FLAG;
    var params = {
      "eid": eid,
      "domain": domain
    };
    return this.postData$<string>(url, params, {responseType: "text"});
  }


  postData$<T>(url: string, param: any, options: any = {}): Observable<any> {
    return this.http.post<T>(url, param, options);
  }
}
