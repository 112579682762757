import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  isHelpPage: boolean = false;
  footerYear: string = '';
  constructor(
    private config: AppConfigService,
    private router: Router){
      this.isHelpPage = router.url === '/help'
      this.footerYear = new Date().getFullYear().toString();
    }
}
