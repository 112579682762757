import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { REBAR_AUTH_GUARD } from './core/rebarauth/rebar.auth.module';
import { BulkRpwComponent } from './pages/resetpassword/bulk/bulk.rpw.component';
import { SingleRpwComponent } from './pages/resetpassword/single/single.rpw.component';
import { ACS_AUTH_GUARD } from './utils/auth/adminchangepassword.guard';
import { AcpComponent } from './pages/adminchangepassword/acp.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { ClaimsResolver } from './utils/auth/claims.resolver';
import { AcpUnauthorizedComponent } from './pages/acp-unauthorized/acp-unauthorized.component';
import { RpwUnauthorizedComponent } from './pages/rpw-unauthorized/rpw-unauthorized.component';
import { PCSHelpComponent } from './pages/pcs-help/pcs-help.component';
import { RPW_AUTH_GUARD } from './utils/auth/resetpassword.guard';

const routes: Routes = [
  {
    path:'' ,
    component: LandingpageComponent,
    canActivate: [REBAR_AUTH_GUARD]
  },
  {
    path:'AdminChangePW' ,
    component: AcpComponent,
    canActivate: [REBAR_AUTH_GUARD, ACS_AUTH_GUARD]
  },
  {
    path:'SingleResetPassword' ,
    component: SingleRpwComponent,
    canActivate: [REBAR_AUTH_GUARD, RPW_AUTH_GUARD],
    data: {
      resolvedFor: 'reset-password-submit'
    },
    resolve: {
      claims: ClaimsResolver
    }
  },
  {
    path:'BulkResetPassword',
    component: BulkRpwComponent,
    canActivate: [REBAR_AUTH_GUARD, RPW_AUTH_GUARD],
    data: {
      resolvedFor: 'perform-bulk-reset'
    },
    resolve: {
      claims: ClaimsResolver
    }
  },
  {
    path:'ACPUnauthorized',
    component:AcpUnauthorizedComponent
  },
  {
    path:'RPWUnauthorized',
    component:RpwUnauthorizedComponent
  },
  {
    path:'help',
    component:PCSHelpComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
