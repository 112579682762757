import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService{

    public key(key: string): StorageKeys{
        let storageKey = new StorageKeys(key);
        return storageKey;
    }
}

class StorageKeys{
    private key: string="";
    constructor(key: string){
        this.key=key;
    }
    public exists(): boolean{
        return localStorage.getItem(this.key) !== null;
    }
    public get(): any{
        return localStorage.getItem(this.key);
    }
    public set(value: any): void{
        localStorage.setItem(this.key, value);
    }
    public remove(): void{
        localStorage.removeItem(this.key);
    }
}
