
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordService } from 'src/app/core/services/resetpassword.service';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { AccountValidators } from 'src/app/utils/validators/accountid.validator';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { DomainValidators } from 'src/app/utils/validators/domain.validator';
import { ForceChangeValidators } from 'src/app/utils/validators/forceChange.validator';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordPayload } from 'src/app/models/ResetPasswordPayload';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ScriptService } from 'src/app/core/services/script.service';
import { WidgetService } from 'src/app/core/services/widgets.service';
import { debounceTime, fromEvent, merge, tap } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';


declare const acnmodal: any;
declare const acntoasts: any;
@Component({
  selector: 'app-single-rpw',
  templateUrl: './single.rpw.component.html',
  styleUrls: ['./single.rpw.component.css'],
  providers: [ScriptService],
})

export class SingleRpwComponent {
  resetPasswordForm!: FormGroup;
  selectedDomain: string = "";
  submitted: boolean = false;
  submit: any = false;
  submitshow: any = false;
  isShowTable: any = false;
  isGeneratingPass: any = false;
  isAdminAccount: boolean = false;
  isLTSAccount: boolean = false;
  isVAAdmin: boolean = false;
  isVALTS: boolean = false;
  isBulkReset: boolean = false;
  hasDIR: boolean = false;
  hasDS: boolean = false;
  ResetEmailFailed: any = "";
  modalUnfcpError: boolean = false;
  modalComplianceFor: string = "";
  showModal: boolean = false;
  loading: boolean = false;
  
  loadingSubmitResetPassword: boolean = false;
  loadingSubmitUnlock: boolean = false;
  loadingSubmitEnable: boolean = false;
  loadingSubmitFCP: boolean = false;
  loadingSubmitResetForm: boolean = false;
  loadingFCPModalYes: boolean = false;
  loadingFCPModalNo: boolean = false;
  loadingComplianceModalYes: boolean = false;
  loadingComplianceModalNo: boolean = false;

  disableNoFCPModal: boolean = false;
  disableYesFCPModal: boolean = false;
  disableNoComplianceModal: boolean = false;
  disableYesComplianceModal: boolean = false;
  disableResetPW_btn: boolean = false;
  disableUnlock_btn: boolean = false;
  disableEnableInAD_btn: boolean = false;
  disableChangePW_btn: boolean = false;
  disableResetForm_btn: boolean = false;

  isResetPWClicked: boolean = false;
  isUnlockClicked: boolean = false;
  isEnableADClicked: boolean = false;
  isFCPClicked: boolean = false;
  isResetFormClicked: boolean = false;

  environment: string = "";
  notificationframeworkAPPID: any;
  userStatusLink: any;


  @ViewChild('modalFCPUnchecked') modalFCPUnchecked: TemplateRef<any> | undefined ;
  @ViewChild('modalComplianceFlag') modalComplianceFlag: TemplateRef<any> | undefined ;

  constructor(
    private fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private rebarAuthService:RebarAuthService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private window: Window,
    private sanitizer: DomSanitizer,
    private scriptService: ScriptService,
    private widgetService: WidgetService,
    private config: AppConfigService
  ) {
    this.environment = (this.config.config['constants'] as any).environment;
    this.notificationframeworkAPPID = (this.config.config['constants'] as any).NOTIFICATION_FRAMEWORK.APPID;
    this.userStatusLink = (this.config.config['constants']as any).user_status;
    this.resetPasswordForm = this.fb.group(
      {
        accountId: [
          '',
          [
            Validators.required,
            Validators.maxLength(70),
            AccountValidators.characterValidator('@')
          ]
        ],
        snowTicket: ['', [Validators.maxLength(100)]],
        domainDir: [false],
        domainDs: [false],
        password: [''],
        forceChangePasswordNextSignIn: [true],
        forceReason: [''],
        ticketClosed: [false],
        laptopHandover: [false],
        ticketNumber:[''],
    },{
        validators: [
          DomainValidators.requiredDomain,
          ForceChangeValidators.requiredReason
        ],
    });
  }

  ngOnInit(): void {
    const constant = this.config.config['constants'] as any;
    const ROLES = constant.ROLES;
    this.activatedRoute.data.subscribe({
      next: (data) => {
        var claims = data.claims;

        this.isLTSAccount = claims.roles.includes(ROLES.LTS);
        this.isBulkReset = claims.roles.includes(ROLES.BULK_RESET);

        if (claims.roles.includes(ROLES.VA_ACCOUNTS_ADMIN)){ //checking of claim
          this.isVAAdmin = true;
          this.isVALTS = true;
          this.resetPasswordForm.controls.forceChangePasswordNextSignIn.disable();
          this.resetPasswordForm.controls.forceChangePasswordNextSignIn.setValue(true);

          if (this.isVAAdmin && this.isLTSAccount){
            this.isVALTS = false;
            this.resetPasswordForm.controls.forceChangePasswordNextSignIn.enable();
          }
        }
        
      }
    });

    this.initialState();
    let submitStorage = this.localStorageService.key("submit_message");
    if (submitStorage.exists()){
      this.submitshow = true;
      this.submit = JSON.parse(<string>submitStorage.get());
      this.submit.message = this.sanitizer.bypassSecurityTrustHtml(this.submit.message);
      this.resetPasswordForm.controls.password.setValue(this.submit.password);
      this.resetPasswordForm.controls.accountId.setValue(this.submit.eid);

      this.DomainSelect(this.submit.domain);
      if (this.submit.domainDir){
        this.resetPasswordForm.controls.domainDir.enable();
      }
      if (this.submit.domainDs){
        this.resetPasswordForm.controls.domainDs.enable();
        if (this.environment == "stage")
          {
            this.resetPasswordForm.controls.domainDs.disable();
          }
      }

      if (this.submit.click == "resetpassbtn"){
        this.isGeneratingPass = true;
        this.isShowTable = true;
      }
      submitStorage.remove();
      this.enableButtons();
    }

    this.scriptService.load('toast','alert','modal').then(() => {
      console.log('Notification Framework loaded and initialized in Single RPW');
      setTimeout(() => {
        acntoasts.show(this.widgetService.toastSourceID);
        console.log('Toast loaded');
      }, 3000);
    });

    merge(
      fromEvent(window, "mousemove"),
      fromEvent(window, "keypress")
    ).pipe(
      debounceTime(this.widgetService.inactivity_threshold),
      tap((event: any) => {
        if (this.resetPasswordForm.controls.accountId.value.trim() != ""){
          console.log('modal loaded');
          acnmodal.show(this.widgetService.modalSourceID);
        }
      })
    ).subscribe();
      }

  onDomainChange(event: any, selectedDomain: string) {
    this.selectedDomain = event.target.checked ? selectedDomain : "";
    this.resetPasswordForm.updateValueAndValidity();
    return this.selectedDomain;
  }

  OnEidInput(event: any){
    var eid = event.target.value;
    //TODO: Loader while waiting for API Return?

    if (!this.isVAAdmin){
      this.isAdminAccount = false;
      this.resetPasswordForm.controls.forceChangePasswordNextSignIn.enable();
      this.resetPasswordForm.controls.forceChangePasswordNextSignIn.setValue(true);

      if (eid!=""){
        if (eid.startsWith("ads.") || eid.startsWith("ads0.") || eid.startsWith("gdn.") ){
          this.isAdminAccount = true;
          this.resetPasswordForm.controls.forceChangePasswordNextSignIn.disable();
          this.resetPasswordForm.controls.forceChangePasswordNextSignIn.setValue(false);
        }
      }
    }
  }

  OnEidChange(event: any){
    var eid = event.target.value;
    this.initialState();
    //TODO: Loader while waiting for API Return?
    this.loading = false;

    if (eid!=""){
      if (eid.indexOf(".") !== -1){
        this.loading = true;
        this.submit = {
          class: "",
          message: ""
        };
        this.resetPasswordService.GetDomain$(eid).subscribe({
          next: (data) => {
            if (data.AccountDomains.includes("DIR")){
              this.hasDIR = true;
              this.loading = false;
              this.enableButtons();
              this.DomainSelect("DIR");
              this.DomainEnabled("DIR");
            }

            if (data.AccountDomains.includes("DS")){
              this.hasDS = true;
              this.loading = false;
              this.enableButtons();
              this.DomainSelect("DS");
              this.DomainEnabled("DS");
              if(this.environment == "stage")
              {
                this.resetPasswordForm.controls.domainDs.disable();
              }
            }

            if (data.AccountDomains.includes("DIR") && data.AccountDomains.includes("DS")){
              this.loading = false;
              this.enableButtons();
              this.DomainSelect("");
              this.DomainEnabled("*");
            }

            if (data.AccountDomains.includes("DIR") === false && data.AccountDomains.includes("DS") === false){
              this.loading = false;
              this.initialState();
            }
            this.submit.message = "";
            this.resetPasswordForm.updateValueAndValidity();
            this.loading = false;
          },
          error: (err) => {
            this.hasDIR = false;
            this.hasDS = false;
            this.loading = false;
            this.submitshow = true;
            this.isShowTable = false;
            this.disableResetForm_btn = false;
            this.disableResetPW_btn = true;
            this.submit = {
              class: "text-danger",
              message: err.error.CheckEmployee
            }
          }
        });
      }
    }
  }

  async onSubmit(event: any) {
    this.modalUnfcpError = false;

    this.submitted = true;
    const buttonId = event.target.id;

    if(buttonId == 'resetpassbtn'){
      this.loadingSubmitResetPassword = true;
      this.isResetPWClicked = true;
      this.disableButtons();
      if (this.isVAAdmin){
        this.resetComplianceModal();
      }
    }
    else if(buttonId == 'unlockaccountbtn'){
      this.loadingSubmitUnlock = true;
      this.isUnlockClicked = true;
      this.disableButtons();
    }
    else if(buttonId == 'enableaccountbtn'){
      this.loadingSubmitEnable = true;
    }
    else if(buttonId == 'forcechangePWbtn'){
      console.log("force change password button clicked");
      this.loadingSubmitFCP = true;
    }

    if (this.resetPasswordForm.invalid){
     this.loadingSubmitResetPassword = false;
     this.loadingSubmitUnlock = false;
     this.loadingSubmitEnable = false;
     this.loadingSubmitFCP = false;
     this.enableButtons();
      return;
    }

    var payload = new ResetPasswordPayload();

    payload.eid             = this.resetPasswordForm.controls.accountId.value!;
    payload.domain          = this.selectedDomain;
    payload.forceChangePasswordNextSignIn = this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value;
    payload.forceReason     = this.resetPasswordForm.controls.forceReason.value;
    payload.ticketClosed    = this.resetPasswordForm.controls.ticketClosed.value;
    payload.laptopHandover  = this.resetPasswordForm.controls.laptopHandover.value;
    payload.snowticket      = this.resetPasswordForm.controls.snowTicket.value;

    //check account compliance
    var isCompliant;
    if(buttonId == "resetpassbtn" || buttonId == "unlockaccountbtn"){
      isCompliant =  await this.checkCompliance(payload);
    }

    if (buttonId == "resetpassbtn" || buttonId == "modal-unfcp-yes" || buttonId == "modal-unfcp-no" || (buttonId == "modal-compliance-yes" && this.modalComplianceFor == "reset")) {
      if(buttonId == "resetpassbtn" || buttonId == "modal-compliance-yes"){
        this.loadingComplianceModalYes = true;
        this.disableYesComplianceModal = true;
        if (payload.snowticket == ""){
          if (!isCompliant && buttonId != "modal-compliance-yes"){
            this.openModal(this.modalComplianceFlag, "sm");
            this.loadingSubmitResetPassword = false;
            this.modalComplianceFor = "reset";
            return;
          }
          if (payload.forceChangePasswordNextSignIn == false){
            this.openModal(this.modalFCPUnchecked, "lg");
            this.loadingSubmitResetPassword = false;
            return;
          }
        }
      }

      if (buttonId == "modal-unfcp-no"){
        payload.forceChangePasswordNextSignIn = true;
        this.loadingFCPModalNo = true;
        this.disableNoFCPModal = true;

        // validation of ticket in uncheck fcp modal
        if (payload.snowticket != ""){
          this.disableNoFCPModal = true;
        }
        
      }

      if (buttonId == "modal-unfcp-yes"){
        payload.snowticket = this.resetPasswordForm.controls.ticketNumber.value;
        this.loadingFCPModalYes = true;
        this.disableYesFCPModal = true;

        if (payload.snowticket == ""){
          this.modalUnfcpError = true;
          this.loadingFCPModalYes = false;
          this.disableYesFCPModal = false;
          return;
        }
      }

      this.resetPasswordService.ResetPasswordSubmit$(payload).subscribe({
        next: (res) => {
          var response = JSON.parse(res);
          this.ResetEmailFailed =response.errorMessage
          this.localStorageService
              .key("submit_message")
              .set(
                JSON.stringify({
                  class: "text-success",
                  message: response.message,
                  password: response.password,
                  wordedPassword: response.wordedpassword,
                  eid : payload.eid,
                  domain: payload.domain,
                  domainDir: this.resetPasswordForm.controls.domainDir.enabled,
                  domainDs: this.resetPasswordForm.controls.domainDs.enabled,
                  click: "resetpassbtn",
                  failMessage: response.errorMessage
                })
              );
          this.window.location.reload();
        },
        error: (err) => {
          var response = JSON.parse(err.error);
          this.enableButtons();
          this.modalService.dismissAll();
          this.closeFCPModal();
          this.loadingSubmitResetPassword = false;
          this.isResetPWClicked = false;
          this.submitshow = true;
            this.submit = {
              class: "text-danger",
              message: this.sanitizer.bypassSecurityTrustHtml(response.message),
              failMessage: response.errorMessage
            }
        }
      });
    }

    if (buttonId == "unlockaccountbtn" || (buttonId == "modal-compliance-yes" && this.modalComplianceFor == "unlock")) {
      if (!isCompliant && buttonId != "modal-compliance-yes"){
        if (payload.snowticket == ""){
          this.openModal(this.modalComplianceFlag, "sm");
          this.loadingSubmitUnlock = false;
          this.modalComplianceFor = "unlock";
          return;
        }
      }

      if (buttonId == "modal-compliance-yes"){
      this.loadingComplianceModalYes = true;
      this.disableYesComplianceModal = true;
      }
      
      this.resetPasswordService.EnableAccountOnPremInAD$(payload).subscribe({
        next: (msg) => {
          this.localStorageService
              .key("submit_message")
              .set(
                JSON.stringify({
                  class: "text-success",
                  message: msg,
                  eid : payload.eid,
                  domain: payload.domain,
                  domainDir: this.resetPasswordForm.controls.domainDir.enabled,
                  domainDs: this.resetPasswordForm.controls.domainDs.enabled
                })
              );
          this.window.location.reload();
        },
        error: (err) => {
          this.loadingSubmitUnlock = false;
          this.isUnlockClicked = false;
          this.isShowTable = false;
          this.submitshow = true;
          this.enableButtons();
          this.submit = {
            class: "text-danger",
            message: this.sanitizer.bypassSecurityTrustHtml(err.error)
          }
          this.modalService.dismissAll();
        }
      });
      console.log("Unlock Account");
    }

    if (buttonId == "enableaccountbtn"){
      this.isEnableADClicked = true;
      this.disableButtons();
      this.resetPasswordService.EnableAccountInAD$(payload).subscribe({
        next: (msg) => {
          this.localStorageService
              .key("submit_message")
              .set(
                JSON.stringify({
                  class: "text-success",
                  message: msg,
                  eid : payload.eid,
                  domain: payload.domain,
                  domainDir: this.resetPasswordForm.controls.domainDir.enabled,
                  domainDs: this.resetPasswordForm.controls.domainDs.enabled
                })
              );
          this.window.location.reload();
        },
        error: (err) => {
          this.loadingSubmitEnable = false;
          this.enableButtons();
          this.submitshow = true;
          this.submit = {
            class: "text-danger",
            message: this.sanitizer.bypassSecurityTrustHtml(err.error)
          }
        }
      });
    }

    if (buttonId == "forcechangePWbtn"){
      this.isFCPClicked = true;
      this.disableButtons();
      this.resetPasswordService.ForceChangePWNextLogon$(payload).subscribe({
        next: (msg) => {
          var response = JSON.parse(msg);
          this.ResetEmailFailed = response.errorMessage
          this.localStorageService
              .key("submit_message")
              .set(
                JSON.stringify({
                  class: "text-success",
                  message: response.message,
                  eid : payload.eid,
                  domain: payload.domain,
                  domainDir: this.resetPasswordForm.controls.domainDir.enabled,
                  domainDs: this.resetPasswordForm.controls.domainDs.enabled,
                  failMessage: response.errorMessage
                })
              );
          this.window.location.reload();
        },
        error: (err) => {
          var response = JSON.parse(err.error);
          this.loadingSubmitFCP = false;
          this.submitshow = true;
          this.enableButtons();
          this.submit = {
            class: "text-danger",
            message: this.sanitizer.bypassSecurityTrustHtml(response.message),
            failMessage: response.errorMessage
          }
        }
      });
    }
  }

  OnFCPCheckboxChange(){
    this.resetPasswordForm.controls.forceReason.setValue('');
    if ((this.hasDIR || this.hasDS || this.isVAAdmin) 
      && this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value)
    {
        this.disableResetForm_btn = false;
        this.disableUnlock_btn = true;
        this.disableEnableInAD_btn = true;
        this.disableChangePW_btn = true;
        this.disableResetPW_btn = true;
        if (this.resetPasswordForm.controls.forceReason.value != ""){
          this.enableButtons();
        }
    } 
    if ((this.hasDIR || this.hasDS || this.isVAAdmin) 
      && !this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value)
    {
        this.enableButtons();
    } 
  }

  OnFCPCommentInput(){
    if (this.hasDIR || this.hasDS || this.isVAAdmin){
      if (this.resetPasswordForm.controls.forceReason.value != "")
      {
        this.enableButtons();
      }
      if (this.resetPasswordForm.controls.forceReason.value == "")
      {
        this.disableResetForm_btn = false;
        this.disableUnlock_btn = true;
        this.disableEnableInAD_btn = true;
        this.disableChangePW_btn = true;
        this.disableResetPW_btn = true;
      }
    }
  }

  initialState(): void{
    this.selectedDomain = "";
    this.resetPasswordForm.controls.domainDir.disable();
    this.isShowTable = false;
    this.disableResetPW_btn = true;
    this.disableUnlock_btn = true;
    this.disableEnableInAD_btn = true;
    this.disableChangePW_btn = true;

    if (this.environment == "stage"){
      this.DomainEnabled("DS");
      this.DomainSelect("DS");
      this.resetPasswordForm.controls.domainDs.disable();
    }else{
      this.resetPasswordForm.controls.domainDs.disable();
    }
    
  }

  DomainSelect(selected: string){
    this.selectedDomain = "";
    this.resetPasswordForm.controls.domainDir?.setValue(false);
    this.resetPasswordForm.controls.domainDs?.setValue(false);

    switch (selected){
      case "DIR" :
        this.selectedDomain = "DIR";
        this.resetPasswordForm.controls.domainDir.setValue(true);
      break;
      case "DS" :
        this.selectedDomain = "DS";
        this.resetPasswordForm.controls.domainDs.setValue(true);
      break;
    }
  }

  DomainEnabled(selected: string){
    this.resetPasswordForm.controls.domainDir?.disable();
    this.resetPasswordForm.controls.domainDs?.disable();
    switch (selected){
      case "DIR" :
        this.resetPasswordForm.controls.domainDir.enable();
      break;
      case "DS" :
        this.resetPasswordForm.controls.domainDs.enable();
      break;
      case "*":
        this.resetPasswordForm.controls.domainDs.enable();
        this.resetPasswordForm.controls.domainDir.enable();
      break;
    }
  }
  
  getWidgetID(id: string): string {
    const constant = this.config.config['constants'] as any;
    const NOTIFICATION_FRAMEWORK = constant.NOTIFICATION_FRAMEWORK;
    return NOTIFICATION_FRAMEWORK[id].WIDGET_ID;
  }

  disableButtons(){
    if (this.loadingSubmitResetPassword) {
      Object.assign(this, { disableUnlock_btn: true, disableEnableInAD_btn: true,
        disableChangePW_btn: true, disableResetForm_btn: true });
    }
    if (this.loadingSubmitUnlock) {
      Object.assign(this, { disableResetPW_btn: true, disableEnableInAD_btn: true, 
        disableChangePW_btn: true, disableResetForm_btn: true });
    }
    if (this.loadingSubmitEnable) {
      Object.assign(this, { disableResetPW_btn: true, disableUnlock_btn: true, 
        disableChangePW_btn: true, disableResetForm_btn: true });
    }
    if (this.loadingSubmitFCP) {
      Object.assign(this, { disableResetPW_btn: true, disableUnlock_btn: true, 
        disableEnableInAD_btn: true, disableResetForm_btn: true });
    }
  }

  enableButtons(){
    this.disableResetPW_btn = false;
    this.disableUnlock_btn = false;
    this.disableEnableInAD_btn = false;
    this.disableChangePW_btn = false;
    this.disableResetForm_btn = false;

    if (this.isVAAdmin){
      this.disableUnlock_btn = true;
      this.disableEnableInAD_btn = true;
      this.disableChangePW_btn = true;

      if (this.isVAAdmin && this.isLTSAccount){
        this.disableChangePW_btn = false;
      }
    }

    if (this.isAdminAccount)
    {
      this.disableResetPW_btn = true;
    }
  }

  closeFCPModal(){
    this.loadingFCPModalNo = false;
    this.loadingFCPModalYes = false;
    this.disableYesFCPModal = false;
    this.disableNoFCPModal = false;
    this.resetPasswordForm.controls.ticketNumber.reset();
  }

  openModal(content: TemplateRef<any> | undefined, modalSize: string){
    this.resetComplianceModal();
    this.modalService.open(content, { size: modalSize, centered: true }).result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed test`);
      },
    );
  }

  closeComplianceModal(){
      this.submitshow = true;
      this.submit = {
        class: "text-danger",
        message: "Error: Tracking Ticket # is required."
      }
      this.isShowTable = false;
      this.isResetPWClicked = false;
      this.isUnlockClicked = false;
      this.loadingComplianceModalNo = true;
      this.disableNoComplianceModal = true;
      this.loadingSubmitUnlock = false;
      this.enableButtons();
      this.modalService.dismissAll();
  }

  resetComplianceModal(){
    Object.assign(this, { loadingComplianceModalNo: false, loadingComplianceModalYes: false, 
      disableNoComplianceModal: false, disableYesComplianceModal: false });
  }

  async checkCompliance(payload: ResetPasswordPayload): Promise<boolean>{
    return new Promise<boolean>(res => {
      this.resetPasswordService.CheckComplianceFlag$(payload.eid, payload.domain).subscribe({
        next: () => {
          res(true);
        },
        error: (err) => {
          if(err.status == 400)
          {
            res(false);
          }
          return;
        }
      });
    });
  }

resetForm(){
    this.loadingSubmitResetForm = true;
    this.initialState();

    this.submitshow = false;
    this.isShowTable = false;
    this.disableResetForm_btn = false;
    this.isResetFormClicked = true;

    this.resetPasswordForm.reset({

      accountId: '',
      snowTicket: '',
      password: '',
      forceChangePasswordNextSignIn: true,
      forceReason: '',
      ticketClosed: false,
      laptopHandover: false,
      ticketNumber: '',
      
    });

    this.submitted = false;
    
   // Clear custom validation errors
    setTimeout(()=> {
    DomainValidators.clearErrors(this.resetPasswordForm);

      this.loadingSubmitResetForm = false;
      if (!this.loadingSubmitResetForm){
        this.isResetFormClicked = false;
      }

    }, 1000);
}
}