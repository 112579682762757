import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from "@angular/router";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";

export const ClaimsResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {

    const rebarauth = inject(RebarAuthService);
    var claims = rebarauth.getUserClaims() as any;
    return claims;
};