import {FormControl, ValidationErrors, Validator } from '@angular/forms';
import { AbstractControl, ValidatorFn } from "@angular/forms";

  export class ForceChangeValidators {

    static requiredReason(control: AbstractControl) : ValidationErrors | null {
        const eid = control.get('accountId');
        const forceReason = control.get('forceReason');
        const forceChangeChecked = control.get('forceChangePasswordNextSignIn')?.value;
        
        if (eid?.value.startsWith("ads.") || eid?.value.startsWith("ads0.") || eid?.value.startsWith("gdn.") ){
            return null;
        }
        if (!forceChangeChecked && forceReason?.value == "") {
            return { requiredForceReason: true };
        }
        return null;
    }

}