import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { ResetPasswordService } from 'src/app/core/services/resetpassword.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { DomainValidators } from 'src/app/utils/validators/domain.validator';
import { ForceChangeValidators } from 'src/app/utils/validators/forceChange.validator';
import { AccountValidators } from 'src/app/utils/validators/accountid.validator';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ScriptService } from 'src/app/core/services/script.service';
import { WidgetService } from 'src/app/core/services/widgets.service';
import { debounceTime, fromEvent, merge, tap } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';

declare const acntoasts: any;
declare const acnmodal: any;
@Component({
  selector: 'app-bulk-rpw',
  templateUrl: './bulk.rpw.component.html',
  styleUrls: ['./bulk.rpw.component.css'],
  providers: [ScriptService],
})

export class BulkRpwComponent {
  resetPasswordForm!: FormGroup;
  submitshow: any = false;
  selectedDomain: string = "";
  submitted: boolean = false;
  submit: any = false;
  isResetPWClicked: boolean = false;
  isAccountFieldEmpty = true;
  accounts: any[] = [];
  processAccounts: any;
  totalEids: any;
  loadingSubmitResetPassword: boolean = false;
  environment: string = "";
  notificationframeworkAPPID: any;
  disableResetPW_btn: boolean = false;

  constructor(
    private fb: FormBuilder,
    private rebarAuthService:RebarAuthService,
    private resetPasswordService: ResetPasswordService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private scriptService: ScriptService,
    private widgetService: WidgetService,
    private config: AppConfigService,
  ) {
    this.environment = (this.config.config['constants'] as any).environment;
    this.notificationframeworkAPPID = (this.config.config['constants'] as any).NOTIFICATION_FRAMEWORK.APPID;
    this.resetPasswordForm = this.fb.group({
      accountId: ['', [Validators.required, AccountValidators.accountIdLimitValidator, AccountValidators.userIdContainsAtSymbolValidator, AccountValidators.characterValidator('@')]],
      snowTicket: ['', [ Validators.maxLength(100)]],
      domainDir: [false],
      domainDs: [false],
      forceChangePasswordNextSignIn: [true],
      forceReason: ['']
    },{
      validators: [
        DomainValidators.requiredDomain,
        ForceChangeValidators.requiredReason
      ],
    });
  }

  ngOnInit(): void {
    this.initialState();

    let submitStorage = this.localStorageService.key("submit_message");
    if (submitStorage.exists()){
      this.isAccountFieldEmpty = false;
      const parse = JSON.parse(submitStorage.get());
      this.accounts = parse.accountsresult;
      this.processAccounts = parse.processedcount;
      this.totalEids = parse.totaleidscount;

      if (this.submit.domainDs && this.environment == "stage"){
        this.resetPasswordForm.controls.domainDs.disable();
      }

      submitStorage.remove();
    }
    
    this.scriptService.load('toast','alert','modal').then(() => {
      console.log('Notification Framework loaded and initialized in Bulk RPW');
      setTimeout(() => {
        acntoasts.show(this.widgetService.toastSourceID);
        console.log('Toast loaded');
      }, 3000);
    });

    merge(
      fromEvent(window, "mousemove"),
      fromEvent(window, "keypress")
    ).pipe(
      debounceTime(this.widgetService.inactivity_threshold),
      tap((event: any) => {
        if (this.resetPasswordForm.controls.accountId.value.trim() != ""){
          console.log('modal loaded');
          acnmodal.show(this.widgetService.modalSourceID);
        }
      })
    ).subscribe();
  }

  onDomainChange(event: any, selectedDomain: string) {
    this.selectedDomain = event.target.checked ? selectedDomain : "";
    this.resetPasswordForm.updateValueAndValidity();
    return this.selectedDomain;
  }

  OnFCPCheckboxChange(){
    if (this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value){
      this.resetPasswordForm.controls.forceReason.setValue("");
    }
    if (this.resetPasswordForm.controls.accountId.value!="" && 
    this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value)
    {
        this.disableResetPW_btn = true;
        if (this.resetPasswordForm.controls.forceReason.value != ""){
          this.disableResetPW_btn = false;
        }
    }
    if (this.resetPasswordForm.controls.accountId.value!="" &&
    !this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value)
    {
      this.disableResetPW_btn = false;
    }
  }

  OnFCPReasonInput(){
    if (this.resetPasswordForm.controls.accountId.value!="" && 
    this.resetPasswordForm.controls.forceReason.value != "")
    {
        this.disableResetPW_btn = false;
    }
    if (this.resetPasswordForm.controls.accountId.value!="" &&
    this.resetPasswordForm.controls.forceReason.value == "")
    {
      this.disableResetPW_btn = true;
    }
  }

  onSubmit(event: any) {
    this.submitted = true;
    const buttonId = event.target.id;

    if(buttonId == 'bulkresetpassbtn'){
      this.loadingSubmitResetPassword = true;
      this.isResetPWClicked = true;
    }
    if (this.resetPasswordForm.invalid){
      this.loadingSubmitResetPassword = false;
      this.isResetPWClicked = false;
      return;
    }

    var accountIdInput = this.resetPasswordForm.controls.accountId.value;
    var forcePasswordNextSignIn = this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value
    var domain = this.selectedDomain;
    var lines = accountIdInput.split(/[\n]/)
                                .map(
                                  (account: string) => account.trim().replace(/\s+/g, '')
                                ).filter(
                                  (account: string) => account != ""
                                );
    var forceReason = this.resetPasswordForm.controls.forceReason.value;
    var snowTicket = this.resetPasswordForm.controls.snowTicket.value;

    this.resetPasswordService.BulkResetPassword$(lines, domain, forcePasswordNextSignIn, forceReason, snowTicket).subscribe({
      next: (data) => {
        this.localStorageService
          .key("submit_message")
          .set(data);
        window.location.reload();
      },
      error: (err) => {
        console.error("An error occured. " + err);
        this.submitshow = true;
        this.loadingSubmitResetPassword = false;
        this.submit = {
          class: "text-danger",
          message: "Something went wrong while processing data."
        }
        this.isResetPWClicked = false;
      }
    }); 
  }

  OnEidChange(event: any){
    var eid = event.target.value;
    this.initialState();
    if(this.resetPasswordForm.controls.accountId.value!=""){
      this.disableResetPW_btn = false;
    }
    if (!this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value &&
      this.resetPasswordForm.controls.forceReason.value == "" &&
      this.resetPasswordForm.controls.accountId.value!=""){
        this.disableResetPW_btn = true;
    }
    if (this.resetPasswordForm.controls.accountId.value!="" &&
      !this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value &&
      this.resetPasswordForm.controls.forceReason.value != ""){
        this.disableResetPW_btn = false;
    }
    
    if (this.environment == "stage"){
      this.resetPasswordForm.controls.domainDs.disable();
      return;
    }
    if(eid == ''){
      this.resetPasswordForm.controls.domainDir.disable();
      this.resetPasswordForm.controls.domainDs.disable();
    }
    else{
      this.resetPasswordForm.controls.domainDir.enable();
      this.resetPasswordForm.controls.domainDs.enable();
    }
  }

  getWidgetID(id: string): string {
    const constant = this.config.config['constants'] as any;
    const NOTIFICATION_FRAMEWORK = constant.NOTIFICATION_FRAMEWORK;
    return NOTIFICATION_FRAMEWORK[id].WIDGET_ID;
  }

  initialState(): void{
    this.disableResetPW_btn = true;
    this.selectedDomain = "";
    this.resetPasswordForm.controls.domainDir.disable();
    if (this.environment == "stage"){
      this.resetPasswordForm.controls.domainDs.enable();
      this.resetPasswordForm.controls.domainDs.setValue(true);
      this.resetPasswordForm.controls.domainDs.disable();
      this.selectedDomain = "DS";
    }else{
      this.resetPasswordForm.controls.domainDs.disable();
    }
  }
}
function ngAfterViewChecked() {
  throw new Error('Function not implemented.');
}

