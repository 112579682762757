<app-header></app-header>

<div class="container-fluid">
    <div id="acn-alert" [attr.data-widgetid]="getWidgetID('acn-alert')" [attr.data-appid]="notificationframeworkAPPID"></div>
    <div class="row mt-2">
        <div class="card card-font col-md-10 offset-md-1 shadow p-1 mb-5 bg-body-tertiary rounded card-alignment">

            <div class="card-body">
                <div class="" style="margin-left: 20px;">
                        <div style="display: flex;">
                            <img src="../assets/images/FailedAccessIcon.png" alt="PCS-Icon" width="25" height="30">
                            <p class="text-danger larger-text" style="margin-top: 5px; margin-left:7px"><strong>Access is denied.</strong></p> 
                        </div>
                    <p class="" style="margin-top:5px;">Please note that Reset Password site is restricted and only Authorized users can access (e.g: Service Desk, Identified LTS members).</p>  
                    <p class="" style="margin-top:5px;">If you were part of the access group and are facing any further issues, while accessing the site, please raise a SNOW ticket and assign it to <b>INFRADELV-IAM-DIR-CONT</b> queue.</p> 
		                <p class="" style="margin-top:5px;">For more information please visit our <a href="https://ts.accenture.com/sites/dsext/Active%20Directory/IAM%20ServiceNow%20Queue%20and%20Ticket%20Assignment%20Matrix.aspx" target="_blank" class="nounderline">IAM Service now Queue and Ticket Assignment Matrix</a>.</p> 
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<br>
<app-footer></app-footer>