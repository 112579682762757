<footer class="footer fixed-bottom">
    <div class="container">
        <div class="row mb-0">
            <ul class="nav justify-content-center footer-links">
                <li class="nav-item" *ngIf="!isHelpPage"><a href="help" class="nav-link px-2 text-primary">Help</a></li>
                <li class="nav-item"><a href="https://accenture.qualtrics.com/jfe/form/SV_01CmYtDgZV4Lj0y" target="_blank" class="nav-link px-2 text-primary">Give Feedback</a></li>
                <li class="nav-item"><a href="https://support.accenture.com/support_portal?id=kb_article&sys_id=7da0dd89db614c906c7f3318f49619be" target="_blank" class="nav-link px-2 text-primary">Terms Of Use</a></li>
            </ul>
        </div>
        <div class="row mb-0">
            <div class="pt-3 pb-2 footer-msg">
                <span>© {{ footerYear }} Accenture. All rights reserved. Accenture Highly Confidential. For internal use only.</span>
            </div>
        </div>
    </div>
</footer>

