<nav class="navbar py-1">
    <span class="navbar-brand mb-0 h1"> 
        <a href="/" style="cursor: default">
            <img src="../assets/images/Acc_Logo_All_White_RGB.png" alt="Accenture-Logo">
        </a>
    </span>
        
    <a class="logout-position" (click)="logout()">
        <span class="logout-text">Sign Out</span>
        <i class="bi bi-box-arrow-right custom-icon"></i>
    </a>
</nav>
<div *ngIf="!isBulk">
    <button class="btn btn-xs" (click)="goBack()">
        <span class="bi bi-arrow-left"> Go Back</span>
    </button>
</div>
