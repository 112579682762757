import { Component } from '@angular/core';
import { ScriptService } from 'src/app/core/services/script.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-rpw-unauthorized',
  templateUrl: './rpw-unauthorized.component.html',
  styleUrls: ['./rpw-unauthorized.component.css'],
  providers: [ScriptService]
})
export class RpwUnauthorizedComponent {
  notificationframeworkAPPID: any;

  constructor(
    private scriptService: ScriptService,
    private config: AppConfigService
  ) {
    this.notificationframeworkAPPID = (this.config.config['constants'] as any).NOTIFICATION_FRAMEWORK.APPID;
  }
  ngOnInit(){
    this.scriptService.load('alert').then(() => {
      console.log('Notification Framework loaded and initialized in Landing Page');
    });
  }

  getWidgetID(id: string): string {
    const constant = this.config.config['constants'] as any;
    const NOTIFICATION_FRAMEWORK = constant.NOTIFICATION_FRAMEWORK;
    return NOTIFICATION_FRAMEWORK[id].WIDGET_ID;
  }
}