/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

declare var document: any;

interface Scripts {
  name: string;
  js_url: string;
  css_url: string;
}

@Injectable()
export class ScriptService {
  private scripts: any = {};
  private config: any;

  constructor(
    private configService: AppConfigService
  ) {

    this.config = (this.configService.config['constants'] as any).NOTIFICATION_FRAMEWORK;
    const toast_js = this.config.TOAST_JS;
    const toast_css = this.config.TOAST_CSS;
    const alert_js = this.config.ALERT_JS;
    const alert_css = this.config.ALERT_CSS;
    const modal_js = this.config.MODAL_JS;
    const modal_css = this.config.MODAL_CSS;

    const ScriptStore: Scripts[] = [
      { name: 'toast', js_url: toast_js, css_url: toast_css },
      { name: 'alert', js_url: alert_js, css_url: alert_css },
      { name: 'modal', js_url: modal_js, css_url: modal_css}
    ];

    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = { loaded: false, js_url: script.js_url, css_url: script.css_url };
    });
  }

  load(...scripts: string[]): Promise<any[]> {
    if (!this.config.ENABLED){
      return Promise.reject("Notification Framework is disabled");
    }
    const promises: Promise<any>[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string): Promise<any> {
    
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        const style = document.createElement('link');
        style.rel  = "stylesheet";
        style.type = "text/css";
        style.href = this.scripts[name].css_url;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].js_url;

        if (script.readyState) {
          // For IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null; 
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // For other browsers
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }

        document.head.appendChild(style);
        document.head.appendChild(script);
      }
    });
  }
}