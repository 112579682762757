


<app-header></app-header>


    <div class="container" >
        <div id="acn-alert" [attr.data-widgetid]="getWidgetID('acn-alert')" [attr.data-appid]="notificationframeworkAPPID"></div>
        <div id="acn-toast" [attr.data-widgetid]="getWidgetID('acn-toast')" [attr.data-appid]="notificationframeworkAPPID"></div>
        <div id="acn-modal" [attr.data-widgetid]="getWidgetID('acn-modal')" [attr.data-appid]="notificationframeworkAPPID"></div>
        
        <div class="row mt-1">
            <p class="top-text text-center" style="font-size: 13px;"> Enterprise Directory Reset Password</p>
        </div>
    
        <div class="row mt-7" >
            <div class="card card-font col-lg-10 col-md-12 col-sm-10 offset-lg-1 offset-md-0 offset-sm-1 shadow p-1 mb-5 bg-body-tertiary rounded card-alignment"> <!--  card-transform-->
                <div class="card-body">
                    <p class="caption text-dark text-center" style="margin-bottom: 0px; font-size:12px"> Do not reset password if the customer is using Mac. <a href="https://go.accenture.com/MacPassword" target="_blank" class="nounderline">Use these instruction instead</a> </p>
    
                    <div class="col-lg-10 col-md-7 offset-md-1" style="width: 90%;">
                        <form [formGroup]="resetPasswordForm">
    
                            <!-- Account ID -->
                            <div class="row mb-7 mt-4" style="margin-top:-10px;margin-left: 0px; margin-right: 0px;">
                                <label for="accountId" class="col-md-4 col-form-label col-form-label-xs fw-bold pt-4">
                                    <div class="label-forms">Enterprise ID</div>
                                    
                                </label>
    
                                <div class="col-lg-5 col-md-5 pt-3 mt-0 input-padding">
                                    <div class="form-group">
                                        <div class="input-container">
                                            <input type="text" class="form-control form-control-xs" id="accountId"
                                            #accountInput
                                            formControlName="accountId"
                                            (change)="OnEidChange($event)"
                                            (input)="OnEidInput($event)"
                                            [ngClass]="{'is-invalid':resetPasswordForm.controls.accountId.hasError('hasInvalidCharacter') || 
                                                        resetPasswordForm.controls.accountId.hasError('maxlength') && !resetPasswordForm.controls.accountId.hasError('hasInvalidCharacter') || 
                                                        submitted && resetPasswordForm.controls.accountId.invalid}">
                                            <div id="domainCheckingLoader" [ngClass]="{'spinner-border': loading}" role="status"></div>
                                        </div>
                                    </div>

                                    <!-- <ng-template #accountTooltip>
                                        <div class="custom-tooltip">
                                            EID, Additional ID in the DIR or DS domain that starts with either "ADS' or 'ADS0' or 'GDN'
                                        </div>
                                    </ng-template>
                                    <i class="bi bi-info-circle custom-tooltip-trigger info-element" style="font-size: 12px; cursor: pointer;"
                                        placement="bottom" [ngbTooltip]="accountTooltip"  triggers="hover click" >
                                    </i> -->
    
                                    <div *ngIf="submitted && resetPasswordForm.controls.accountId.hasError('required')" class="text-danger text-danger-format">
                                        Please enter Enterprise ID.
                                    </div>
                                    <div *ngIf="resetPasswordForm.controls.accountId.hasError('hasInvalidCharacter')" class="text-danger text-danger-format">
                                        Please remove any email suffix (ie. &#64;accenture.com) before continuing.
                                    </div>
                                    <div *ngIf="resetPasswordForm.controls.accountId.hasError('maxlength') && !resetPasswordForm.controls.accountId.hasError('hasInvalidCharacter')" class="text-danger text-danger-format">
                                        Enterprise ID cannot be longer than 70 characters.
                                    </div>
    
                                    <div class="form-label col-form-label-xs" *ngIf="isBulkReset">
                                        <i class="bulk-icon" style="font-size: 12px; cursor: pointer;"></i>
                                        <img src="../assets/images/BulkInsertLinkIcon.png" alt="Bulk-Icon" width="20" height="20">
                                        <a href="BulkResetPassword" class="nounderline" style="margin-left: 8px; font-weight: bold;" [ngClass] = "{ 'disabled': isAdminAccount }">Bulk EID Reset Password</a>
                                    </div>
                                </div>
    
                                <div class="col-md-3 offset-md-0 mt-3 form-label col-form-label-xs">
                                    <p><a href="{{ userStatusLink }}" target="_blank" class="nounderline">Check User Status</a></p>
                                </div>
                            </div>
    
                            <!-- Domain -->
                            <div class="row mb-3 mt-0" style="margin-left: 0px; margin-right: 0px;">
                                <label for="domain" class="col-md-4 col-form-label col-form-label-xs fw-bold">
                                    Domain
                                </label>
    
                                <div class="col-md-7 mt-1 check-padding">
                                    <div class="form-check-inline form-switch">
                                        <input class="form-check-input form-domain" type="checkbox" formControlName="domainDir" id="radio-dir" value="DIR"
                                            formControlName="domainDir" id="radio-dir" value="DIR"
                                            #domainDir
                                            [checked]="domainDir.value === selectedDomain"
                                            (change)="onDomainChange($event, domainDir.value)"
                                        >
                                        <label class="form-check-label" for="radio-dir" style="font-size:12px; margin-right: 15px;">DIR(Production)</label>
                                    </div>
                                    <div class="form-check-inline form-switch">
                                        <input class="form-check-input form-domain" type="checkbox" formControlName="domainDs" id="radio-ds" value="DS"
                                            formControlName="domainDs" id="radio-ds" value="DS"
                                            #domainDs
                                            [checked]="domainDs.value === selectedDomain"
                                            (change)="onDomainChange($event, domainDs.value)"
                                        >
                                        <label class="form-check-label" for="radio-ds" style="font-size:12px;">DS(Stage)</label>
                                    </div>
    
                                    <ng-template #domainTooltip>
                                        <div class="custom-tooltip" >
                                            Only one domain DIR or DS password can be changed at a time.
                                        </div>
                                    </ng-template>
                                    <i class="bi bi-info-circle icon-alignment custom-tooltip-trigger" style="font-size: 12px; cursor: pointer;"
                                        placement="right" [ngbTooltip]="domainTooltip"  triggers="hover click">
                                    </i>
                                    <div *ngIf="submitted && resetPasswordForm.hasError('requiredDomain')"class="text-danger text-danger-format">
                                        Please select Domain.
                                    </div>
                                </div>
                            </div>
    
                            <div class="row mb-3 input-padding" style="margin-left: 0px; margin-right: 0px;">
                                <label for="snow-ticket" class="col-md-4 col-form-label col-form-label-xs fw-bold">Service Now Ticket #</label>
                                <div class="col-md-5 input-padding">
                                    <div class="input-snow-container">
                                        <input type="text" class="form-control form-control-xs" id="snow-ticket"
                                            #snowTicket
                                            formControlName="snowTicket"
                                            style="resize: none"
                                            [ngClass]="{'is-invalid':resetPasswordForm.controls.snowTicket.hasError('maxlength')}"
                                        >
    
                                <div *ngIf="resetPasswordForm.controls.snowTicket.hasError('maxlength')" class="text-danger text-danger-format">
                                    ServiceNow/Tracking Ticket # cannot be longer than 100 characters.
                                </div>
                                    </div>
                                </div>
                            </div>
    
                            <!-- Force Change checkbox-->
                            <div class="row mb-3" style="margin-left: 0px; margin-right: 0px;" *ngIf="isLTSAccount || isVAAdmin">
                                <label for="flexCheckDefault" class="col-lg-4 col-md-4 col-form-label col-form-label-xs fw-bold">
                                    <div>Force Change Password at Next Logon</div>
                                    <div class="text-muted" style="line-height: 1.1em; font-size: 0.77em;font-weight: 450;">
                                        It will enable Force Password Change at Next Logon for Reset Password button only.
                                    </div>
                                </label>
                                <div class="col-md-4 input-padding align-items-center d-flex">
                                    <input class="form-check-input" type="checkbox" formControlName="forceChangePasswordNextSignIn" id="flexCheckDefault"
                                    (click)="OnFCPCheckboxChange()">
                                    <!-- <i class="bi bi-info-circle icon-alignment" style="font-size: 12px; cursor: pointer;"
                                        placement="right" [ngbTooltip]="forceChange"  triggers="hover click">
                                    </i>
                                    <ng-template #forceChange>
                                        <div class="custom-tooltip">
                                            It will enable Force Password Change at Next Logon for Reset Password user only
                                        </div>
                                    </ng-template> -->
                                </div>
                            </div>
    
                            <!-- Reason Comment Textbox -->
                            <div class="row mb-3 input-padding" style="margin-left: 0px; margin-right: 0px;" *ngIf="!this.resetPasswordForm.controls.forceChangePasswordNextSignIn.value && !isAdminAccount">
                                <label for="reason" class="col-md-4 col-form-label col-form-label-xs fw-bold">
                                    <br> Reason/Comment <br>
                                </label>
    
                                <div class="col-md-5 input-padding">
                                    <div class="form-group">
                                        <textarea class="form-control default-text"
                                            rows="4"
                                            id="reason"
                                            style="resize: none; padding-bottom:0px;"
                                            formControlName="forceReason"
                                            (input)="OnFCPCommentInput()"
                                            [ngClass]="{'is-invalid':submitted && resetPasswordForm.hasError('requiredForceReason')}"
                                        ></textarea>
                                    </div>
                                    <ng-template #reasonTooltip>
                                        <div class="custom-tooltip">
                                            Reason to uncheck Force Password Change at Next Logon
                                        </div>
                                    </ng-template>
                                    <i class="bi bi-info-circle icon-alignment custom-tooltip-trigger info-element" style="font-size: 12px; cursor: pointer; margin-top: -84px;"
                                        placement="bottom" [ngbTooltip]="reasonTooltip" triggers="hover click">
                                    </i>
    
                                    <div *ngIf="submitted && resetPasswordForm.hasError('requiredForceReason')" class="text-danger text-danger-format text-sm">
                                        Please enter Reason for unchecking Force Password Change at Next Logon.
                                    </div>
                                </div>
                            </div>
                            <!-- New Password Table -->
                         <div *ngIf="isShowTable">
                            <div *ngIf="isGeneratingPass" class="row mt-3">
                                <table class="table" style="width:100%; margin-bottom:30px" >
                                    <colgroup>
                                        <col style="width: 15rem;">
                                        <col style="width: 15rem;">
                                        <col style="width: 70rem;">
                                    </colgroup>
                                    <tr>
                                        <td class="top-text custom-td" style="font-weight: 600; text-align: left;">
                                            Account Name
                                        </td>
                                        <td class="top-text custom-td" style="font-weight: 600; text-align: left;">
                                            Password
                                        </td>
                                        <td class="top-text custom-td" style="font-weight: 600; text-align: left;">
                                            Worded Password
                                        </td>
                                    </tr>
                                    <tr class="td-result">
                                        <td>{{ submit.eid }}</td>
                                        <td>{{ submit.password }}</td>
                                        <td>{{ submit.wordedPassword?.join(" ") }}</td>
                                    </tr>
                                </table>
                            </div>
                         </div>
    
                            <!-- Submit button -->
                            <div class="" style="margin-left:-60px;background-color:transparent;display: flex; flex-direction: column; justify-content: center;">
                                <div class="button-container d-grid gap-2 d-md-block btn-alignment">
                                    <a class="button-main btn btn-purple btn-sm px-3 mb-2 mb-md-0 mr-md-2" id="resetpassbtn" style="margin-right: 7px;cursor: pointer;font-weight: 650;"
                                        placement="bottom" [ngbTooltip]="resetpassword" (click)="onSubmit($event)" 
                                        [ngClass]="{'disabled': disableResetPW_btn, 'clicked': isResetPWClicked}">
                                        <span [ngClass]="{'spinner-border': loadingSubmitResetPassword}" role="status"></span>
                                        Reset Password
                                        <ng-template #resetpassword>
                                            <div class="custom-tooltip">
                                                Click to Reset user password
                                            </div>
                                        </ng-template>
                                    </a>                             
                                    <a class="button-sub btn btn-purple btn-sm px-3" id="unlockaccountbtn" style="margin-right: 5px;font-weight: 650;cursor: pointer;"
                                        placement="bottom"
                                        [ngbTooltip]="unlockaccount"
                                        (click)="onSubmit($event)"
                                        [ngClass] = "{ 'disabled': isAdminAccount || isVAAdmin || disableUnlock_btn, 'clicked': isUnlockClicked }"
                                    >
                                    <span [ngClass]="{'spinner-border': loadingSubmitUnlock}" role="status"></span>
                                        Unlock Account
                                        <ng-template #unlockaccount>
                                            <div class="custom-tooltip">
                                                Click to unlock User Account
                                            </div>
                                        </ng-template>
                                    </a>
                                    <a class="button-sub btn btn-purple btn-sm px-3" id="enableaccountbtn" style="margin-right: 5px;font-weight: 650; cursor: pointer;"
                                        placement="bottom"
                                        [ngbTooltip]="enableaccount"
                                        (click)="onSubmit($event)"
                                        [ngClass] = "{ 'disabled': isAdminAccount || isVAAdmin || disableEnableInAD_btn, 'clicked': isEnableADClicked }"
                                    >
                                    <span [ngClass]="{'spinner-border': loadingSubmitEnable}" role="status"></span>
                                        Enable Account in Azure AD
                                        <ng-template #enableaccount>
                                            <div class="custom-tooltip">
                                                Click to Enable Account to enabled account in Azure AD while clearing the security compliance flag.
                                            </div>
                                        </ng-template>
                                    </a>
                                    <a class="button-sub btn btn-purple btn-sm px-3" id="forcechangePWbtn" style="margin-right: 5px; font-weight: 650;cursor: pointer;"
                                        placement="bottom"
                                        [ngbTooltip]="changepassword"
                                        (click)="onSubmit($event)"
                                        [ngClass] = "{ 'disabled': isAdminAccount || isVALTS || disableChangePW_btn, 'clicked': isFCPClicked }"
                                    >
                                    <span [ngClass]="{'spinner-border': loadingSubmitFCP}" role="status"></span>
                                        Change Password on Next Logon
                                        <ng-template #changepassword>
                                            <div class="custom-tooltip">
                                                Click to enable Force Change Password on Next Logon without resetting the password
                                            </div>
                                        </ng-template>
                                    </a>
                                    <!-- <a class="btn btn-white btn-sm px-1" id="changepassbtn" style="margin-right: 5px; font-weight: 650;cursor: pointer;" placement="bottom" [ngbTooltip]="resetform">
                                        Reset Form
                                        <ng-template #resetform>
                                            <div class="custom-tooltip">
                                                Click to reset the form
                                            </div>
                                        </ng-template>
                                    </a> -->
                                    <a class="button-sub btn btn-purple btn-sm px-3" id="resetformbtn" style="margin-right: 5px; font-weight: 650;cursor: pointer;"
                                    placement="bottom"
                                    [ngbTooltip]="resetform"
                                    [ngClass] = "{ 'disabled': disableResetForm_btn, 'clicked': isResetFormClicked}"
                                    (click)="resetForm()"
                                    >
                                    
                                     <span [ngClass]="{'spinner-border': loadingSubmitResetForm}" role="status"></span>
                                        Reset Form
                                        <ng-template #resetform>
                                            <div class="custom-tooltip">
                                                Click to reset the form
                                            </div>
                                        </ng-template>
                                    </a>
                                </div>
                            </div>
    
                        </form>
                    </div>           
    
                    <div *ngIf="submitshow" class="row mt-3">
                        <div *ngIf="submit" [innerHTML]="submit.message" [ngClass]="submit.class" class="text-sm text-center" style="font-size:10px;"></div>
                        <div *ngIf="!ResetEmailFailed" class="text-danger text-sm text-center" style="font-size:10px;">{{submit.failMessage}}</div>
                    </div>
                    
    
                </div>
            </div>
        </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <app-footer></app-footer>
    
    <ng-template #modalFCPUnchecked let-modal>
        <form [formGroup]="resetPasswordForm">
            <div class="modal-body" style="font-size: 15px;">
                <div class="m-4">
                    <div class="row">
                        <p><b>Are you sure to uncheck the Force Change Password on the Next Logon?</b></p>
                    </div>
    
                    <div class="row mt-3">
                        <div class="col-md-5">
                            <p>Ticket is closed?</p>
                        </div>
                        <div class="switchToggle col-md-7">
                            <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer" formControlName="ticketClosed">
                            <label for="site_state" class="form-check-label"></label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <p>Laptop is ready to handover?</p>
                        </div>
                        <div class="switchToggle col-md-7">
                            <input type="checkbox" class="form-check-input" id="switch" style="cursor: pointer" formControlName="laptopHandover">
                            <label for="switch" class="form-check-label"></label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label for="inputTicket#" class="col-sm-2 col-form-label"><strong>Ticket #:</strong></label>
                        <div class="col-sm-3">
                            <input type="Ticket #" class="form-control form-control-sm" id="inputTicket#" formControlName="ticketNumber">
                        </div>
                        <div class="col-sm-7 text-danger" [ngClass]="{'d-none': !modalUnfcpError}">
                            Please enter Ticket #.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <b>Note:</b>
                    </div>
                    <div class="row mt-2">
                        <p>If you click <strong>YES</strong> then you might be contacted by Accenture Security Team as this is an exception  of Accenture Standard Security Policy.</p>
                    </div>
                    <div class="row mt-2">
                        <p>If you click <strong>NO</strong> then Force Change Password at Next Logon will be automatically enabled for the user as per Accenture Standard Security Policy.</p>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-success btn" [ngClass]="{'disabledModal-btn': disableNoFCPModal}" (click)="onSubmit($event)" id="modal-unfcp-yes">
                    <span [ngClass]="{'spinner-border': loadingFCPModalYes}" role="status"></span>
                    YES
                </button>
                <button type="button" class="btn btn-outline-danger btn" [ngClass]="{'disabledModal-btn': disableYesFCPModal}" (click)="onSubmit($event)" id="modal-unfcp-no">
                    <span [ngClass]="{'spinner-border': loadingFCPModalNo}" role="status"></span>
                    NO
                </button>
            </div>
        </form>
    </ng-template>
    <ng-template #modalComplianceFlag let-modal>
            <div class="modal-body">
              <p>Is Machine Compliant?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-success btn" [ngClass]="{'disabledModal-btn': disableNoComplianceModal}" (click)="onSubmit($event)" id="modal-compliance-yes">
                <span [ngClass]="{'spinner-border': loadingComplianceModalYes}" role="status"></span>
                YES
            </button>
              <button type="button" class="btn btn-outline-danger btn" [ngClass]="{'disabledModal-btn': disableYesComplianceModal}" (click)="closeComplianceModal()" id="modal-compliance-no">
                <span [ngClass]="{'spinner-border': loadingComplianceModalNo}" role="status"></span>
                NO
             </button>
            </div>
    </ng-template>


