<app-header></app-header>
<div class = "scrollable-container">
<div class="container">
    <div id="acn-alert" [attr.data-widgetid]="getWidgetID('acn-alert')" [attr.data-appid]="notificationframeworkAPPID"></div>
    <!-- <div id="acn-toast" data-widgetid="20719" data-appid="18919"></div> -->
    <div id="acn-modal" [attr.data-widgetid]="getWidgetID('acn-modal')" [attr.data-appid]="notificationframeworkAPPID"></div>

    <div class="row mt-2 mb-0">
        <p class="top-text text-center"> Enterprise Directory Bulk Password Reset </p>
    </div>

    <div class="row mt-0 justify-content-center">
        <div class="card card-font col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-7 col-xxxl-7 shadow p-1 mb-0 bg-body-tertiary rounded">

            <div class="card-body">
                <p class="caption text-dark text-center"> Do not reset password if the customer is using Mac. <a href="https://go.accenture.com/MacPassword" target="_blank" class="nounderline">Use these instruction instead</a> </p>
                <div class="col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 col-xxl-10 offset-xxl-1 col-xxxl-10 offset-xxxl-1">
                    <form [formGroup]="resetPasswordForm">

                        <!-- Account ID -->
                        <div class="row mb-2">
                            <label for="accountId" class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-form-label col-form-label-xs fw-bold pt-0">
                            Enterprise ID
                            </label>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div class="form-group">
                                    <textarea class="form-control form-control-xs" rows="4" id="accountId" style="resize: none" formControlName="accountId" (input)="OnEidChange($event)"
                                    [ngClass]="{'is-invalid':resetPasswordForm.controls.accountId.hasError('hasInvalidCharacter') || 
                                                resetPasswordForm.controls.accountId.hasError('accountIdLimit') || 
                                                submitted && resetPasswordForm.controls.accountId.invalid}"></textarea>
                                </div>
                                <!-- <ng-template #accountTooltip>
                                    <div class="custom-tooltip">
                                        You can enter multiple accounts of additional ID in the DIR or DS domain that starts with either 'ADS' or 'ADS0' or 'GDN'.
                                    </div>
                                </ng-template>
                                <i class="bi bi-info-circle icon-alignment custom-tooltip-trigger info-element" style="font-size: 14px; cursor: pointer;"
                                placement="bottom" [ngbTooltip]="accountTooltip" triggers="hover click">
                                </i> -->

                                <div *ngIf="submitted && resetPasswordForm.controls.accountId.hasError('required')" class="text-danger text-danger-format text-sm">
                                    Please enter Enterprise ID.
                                </div>
                                <div *ngIf="resetPasswordForm.controls.accountId.hasError('hasInvalidCharacter')" class="text-danger text-danger-format">
                                    Please remove any email suffix (ie. &#64;accenture.com) before continuing.
                                </div>
                                <div *ngIf="submitted && resetPasswordForm.controls.accountId.hasError('hasAdminAccount')" class="text-danger text-danger-format text-sm">
                                    Account not found.
                                </div>
                                <div *ngIf="resetPasswordForm.controls.accountId.hasError('accountIdLimit')" class="text-danger text-danger-format text-sm">
                                    Only 500 Accounts can be processed per transaction.
                                </div>
                            </div>
                        </div>

                        <!-- Domain -->
                        <div class="row mb-2">
                            <label for="domain" class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-form-label col-form-label-xs fw-bold">
                                Domain
                            </label>

                            <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                <div class="form-check-inline form-switch">
                                    <input class="form-check-input form-domain" type="checkbox" formControlName="domainDir" id="radio-dir" value="DIR"
                                    #domainDir
                                        [checked]="domainDir.value === selectedDomain"
                                        (change)="onDomainChange($event, domainDir.value)"
                                    >
                                    <label class="form-check-label" for="radio-dir">DIR(Production)</label>
                                </div>
                                <div class="form-check-inline form-switch">
                                    <input class="form-check-input form-domain" type="checkbox" formControlName="domainDs" id="radio-ds" value="DS"
                                    #domainDs
                                        [checked]="domainDs.value === selectedDomain"
                                        (change)="onDomainChange($event, domainDs.value)"
                                    >
                                    <label class="form-check-label" for="radio-ds">DS(Stage)</label>
                                    <i class="bi bi-info-circle icon-alignment info-element"
                                    placement="auto" [ngbTooltip]="domainTooltip" triggers="hover click">
                                    <ng-template #domainTooltip>
                                        <div class="custom-tooltip">
                                            Only one domain DIR or DS password can be changed at a time.
                                        </div>
                                    </ng-template>
                                </i>
                                </div>
                                <div *ngIf="submitted && resetPasswordForm.hasError('requiredDomain')" class="text-danger text-danger-format text-sm">
                                    Please select Domain.
                                </div>
                            </div>
                        </div>

                        <!-- SnowTicket -->
                        <div class="row mb-1">
                            <label for="snow-ticket" class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-form-label col-form-label-xs fw-bold">Service Now Ticket #</label>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <input type="text" class="form-control form-control-xs" id="snow-ticket"
                                #snowTicket
                                formControlName="snowTicket"
                                [ngClass]="{'is-invalid':resetPasswordForm.controls.snowTicket.hasError('maxlength')}">

                                <div *ngIf="resetPasswordForm.controls.snowTicket.hasError('maxlength')" class="text-danger text-danger-format text-sm">
                                    ServiceNow/Tracking Ticket # cannot be longer than 100 characters.
                                </div>
                            </div>
                        </div>

                        <!-- Force Change-->
                        <div class="row mb-1">
                            <label for="flexCheckDefault" class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-form-label col-form-label-xs fw-bold">Force Change Password at Next Logon</label>
                            <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                <input class="form-check-input" type="checkbox" formControlName="forceChangePasswordNextSignIn" id="flexCheckDefault"
                                (click)="OnFCPCheckboxChange()">
                                    <i class="bi bi-info-circle icon-alignment info-element"
                                        placement="auto" [ngbTooltip]="forceChange" triggers="hover click">
                                    </i>
                                    <ng-template #forceChange>
                                        <div class="custom-tooltip">
                                            It will enable the multiple accounts have a Force Password Change at Next Logon for Reset Password
                                        </div>
                                    </ng-template>
                            </div>
                        </div>

                        <!-- Reason Comment Textbox -->
                        <div class="row mb-3" *ngIf="!this.resetPasswordForm.get('forceChangePasswordNextSignIn')?.value">
                            <label for="reason" class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-form-label col-form-label-xs fw-bold">
                            Reason/Comment
                            </label>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div class="form-group">
                                    <textarea   class="form-control form-control-xs"
                                        rows="4"
                                        id="reason"
                                        style="resize: none"
                                        formControlName="forceReason"
                                        (input)="OnFCPReasonInput()"
                                        [ngClass]="{'is-invalid':submitted && resetPasswordForm.hasError('requiredForceReason')}"
                                    ></textarea>
                                </div>
                                <ng-template #reasonTooltip>
                                    <div class="custom-tooltip">
                                        Reason to uncheck Force Password Change at Next Logon
                                    </div>
                                </ng-template>
                                <i class="bi bi-info-circle form-element info-element"
                                placement="auto" [ngbTooltip]="reasonTooltip" triggers="hover click">
                                </i>

                                <div *ngIf="submitted && resetPasswordForm.hasError('requiredForceReason')" class="text-danger text-danger-format text-sm">
                                    Please enter Reason for unchecking Force Password Change at Next Logon.
                                </div>
                            </div>
                        </div>


                        <!-- Submit button -->
                        <div class="row mb-1">
                            <div class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-form-label col-form-label-xs fw-bold"></div>
                            <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 btn-alignment">
                                <button type="submit" class="btn btn-purple btn-sm px-2 me-2 button-main" id="bulkresetpassbtn" (click)="onSubmit($event)"
                                    [ngClass]="{ 'disabled': disableResetPW_btn, 'clicked': isResetPWClicked}">
                                    <span [ngClass]="{'spinner-border': loadingSubmitResetPassword}" role="status"></span>
                                        Reset Password
                                </button>
                                <a class="btn btn-purple btn-sm px-1" id="bulkreset"  href="SingleResetPassword"
                                [ngClass]="{ 'disabled': loadingSubmitResetPassword}">
                                    Back to Home
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="submitshow" class="row mt-3">
                    <div *ngIf="submit" [ngClass]="submit.class" class="text-sm text-center text-danger-format">
                        {{submit.message}}
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- table for result-->
    <div *ngIf="!isAccountFieldEmpty">
        <div class="container">
            <div class="row mt-3 mb-2">
                <div class="text-sm text-center text-success" style="font-size:17px; font-family: graphik;">
                    Processed Accounts {{processAccounts}}/{{totalEids}}
                </div>
            </div>
            <div class="row mt-2">
                <table class="table" style="width:100%" #processedAccounts>
                    <colgroup>
                        <col style="width: 15rem;">
                        <col style="width: 15rem;">
                        <col style="width: 50rem;">
                        <col style="width: 30rem;">

                    </colgroup>

                    <tr>
                        <td class="top-text custom-td text-align: left">
                            Enterprise ID
                        </td>
                        <td class="top-text custom-td text-align: left">
                            Password
                        </td>
                        <td class="top-text custom-td text-align: left">
                            Worded Password
                        </td>
                        <td class="top-text custom-td text-align: left">
                            Status
                        </td>
                    </tr>
                    <!-- Sample data rows -->
                    <tr *ngFor="let users of accounts" class="td-result">
                        <td>{{ users.eid }}</td>
                        <td>{{ users.password }}</td>
                        <td>{{ users.wordedpassword?.join(" ") }}</td>
                        <td [ngStyle] = "{'color': users.statuscolor}">{{ users.message }}</td>
                    </tr>

                </table>
            </div>
        </div>

    </div>
</div>
<br>
<br>
<br>
<app-footer></app-footer>
</div>
