import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { ResetPasswordService } from 'src/app/core/services/resetpassword.service';

@Injectable({
    providedIn: 'root'
})
export class RPW_AUTH_GUARD{
    constructor(
        private router: Router,
        private resetPasswordService: ResetPasswordService,
        private rebarAuthService:RebarAuthService,
    ){}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let data = next.data.resolvedFor;
        return new Observable<boolean>(obs => {
            this.resetPasswordService.GuardAuth$(data).subscribe({
                complete: () => {
                    obs.next(true);
                },
                error: (err) => {
                    this.router.navigate(['RPWUnauthorized']);
                    obs.next(false);
                }
            });
        });
    }
}
