import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  toastSourceID: string;
  modalSourceID: string;
  inactivity_threshold: number; //in milliseconds
  
  constructor(
    private config: AppConfigService
  ) {
    const constant = (config.config['constants'] as any).NOTIFICATION_FRAMEWORK;
    this.toastSourceID = constant.TOAST_SOURCE;
    this.modalSourceID = constant.MODAL_SOURCE;
    this.inactivity_threshold = constant.INACTIVITY_TRESHOLD;
  }
}
