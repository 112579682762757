import { Component } from '@angular/core';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { ScriptService } from 'src/app/core/services/script.service';
import { WidgetService } from 'src/app/core/services/widgets.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';

declare const acntoasts: any;
@Component({
  selector: 'app-pcs-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
  providers: [ScriptService],
})
export class LandingpageComponent {

  auth_error: any = false;
  modalSourceID: string = this.widgetService.modalSourceID;
  toastSourceID: string = this.widgetService.toastSourceID;
  notificationframeworkAPPID: any;

  constructor(
    private rebarAuthService:RebarAuthService,
    private localStorageService: LocalStorageService,
    private scriptService: ScriptService,
    private widgetService: WidgetService,
    private config: AppConfigService

  ) {
    this.notificationframeworkAPPID = (this.config.config['constants'] as any).NOTIFICATION_FRAMEWORK.APPID;
  }

  ngOnInit(){
    let authErrorStorage = this.localStorageService.key("auth_error");
    if (authErrorStorage.exists()){
      this.auth_error = authErrorStorage.get();
      authErrorStorage.remove();
    }

    this.scriptService.load('toast','alert','modal').then(() => {
      console.log('Notification Framework loaded and initialized in Landing Page');
      setTimeout(() => {
        console.log('Toast loaded');
        acntoasts.show(this.toastSourceID);
      }, 3000);
    });
  }

  getWidgetID(id: string): string {
    const constant = this.config.config['constants'] as any;
    const NOTIFICATION_FRAMEWORK = constant.NOTIFICATION_FRAMEWORK;
    return NOTIFICATION_FRAMEWORK[id].WIDGET_ID;
  }

  logout() {
    this.rebarAuthService.logout();
  }
}
