import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {

  isBulk: boolean = false;
  isAuthorized: boolean = true;

    constructor(
      private router: Router,
      private location: Location,
      private window: Window,
      private rebarAuthService:RebarAuthService) {

    this.isBulk = router.url === '/BulkResetPassword'
    }

    goBack() {
      if (this.router.url === '/help') {
        this.location.back();
      } else {
        this.window.location.href = "/";
      }
    }

    logout() {
      this.rebarAuthService.logout();
    }
}
