<app-header></app-header>

<div class="container-fluid">
    <div id="acn-alert" [attr.data-widgetid]="getWidgetID('acn-alert')" [attr.data-appid]="notificationframeworkAPPID"></div>
    <div id="acn-toast" [attr.data-widgetid]="getWidgetID('acn-toast')" [attr.data-appid]="notificationframeworkAPPID"></div>
    <div id="acn-modal" [attr.data-widgetid]="getWidgetID('acn-modal')" [attr.data-appid]="notificationframeworkAPPID"></div>

    <div class="row mt-0">
        <p class="top-text text-center"> Change Your Secondary Administrative Account ADS/ADS0/GDN/Secondary Mailbox ID Password </p>
    </div>

    <div class="row mt-0 justify-content-center">
        <div class="card card-font col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-6 col-xxxl-6 shadow p-1 mb-0 bg-body-tertiary rounded ">

            <div class="card-body"> <!--style="padding-bottom: 0px; padding-right: 0px; padding-top: 10px; padding-left: 0px;" card-transform card-min-->
                <p class="caption text-danger text-center"> DO NOT use the same password for this account as your Accenture Enterprise ID</p>
                <div class="col-sm-12 offset-sm-0 col-md-12 offset-md-0 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 col-xxl-8 offset-xxl-2 col-xxxl-6 offset-xxxl-3"><!--style="margin-left: 160px"-->
                    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">

                        <!-- Account ID -->
                        <div class="row mb-2">
                            <label for="accountId" class="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-form-label col-form-label-xs fw-bold">
                                Account Name
                            </label>

                            <div class="col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-7 pt-1">
                                <div class="input-container">
                                    <input type="text" class="form-control form-control-xs" id="accountId" formControlName="accountId"
                                    (change)="OnEidChange($event)"
                                    [ngClass]="{'is-invalid':changePasswordForm.controls.accountId.hasError('hasInvalidCharacter') || 
                                    changePasswordForm.controls.accountId.hasError('maxlength') && !changePasswordForm.controls.accountId.hasError('hasInvalidCharacter') || 
                                                submitted && changePasswordForm.controls.accountId.invalid}">
                                    <div id="domainCheckingLoader" [ngClass]="{'spinner-border': loading}" role="status"></div>
                                </div>
                                <ng-template #accountTooltip>
                                    <div class="custom-tooltip text-center">
                                        This account is your additional ID in the DIR or DS domain that starts with either 'ADS' or 'ADS0' or 'GDN' and for Secondary Mailbox ends with 'ACN'.
                                    </div>
                                </ng-template>
                                <i class="bi bi-info-circle form-element info-element"
                                    placement="auto" [ngbTooltip]="accountTooltip"  triggers="hover click"
                                ></i>

                                <div *ngIf="submitted && changePasswordForm.controls.accountId.hasError('required')" class="text-danger text-danger-format">
                                    Please enter Administrative Account Name.
                                </div>
                                
                                <div *ngIf="changePasswordForm.controls.accountId.hasError('hasInvalidCharacter')" class="text-danger text-danger-format">
                                    Please remove any email suffix (ie. &#64;accenture.com) before continuing.
                                </div>

                                <div *ngIf="changePasswordForm.controls.accountId.hasError('maxlength') && !changePasswordForm.controls.accountId.hasError('userIdContainsAtSymbolValidator')" class="text-danger text-danger-format">
                                    Account Name cannot be longer than 70 characters.
                                </div>
                            </div>
                        </div>
        
    
                        <!-- Domain -->
                        <div class="row mb-2">
                            <label for="domain" class="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-form-label col-form-label-xs fw-bold">
                                Domain
                            </label>

                            <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                <div class="form-check-inline form-switch">
                                    <input class="form-check-input form-domain" type="checkbox" formControlName="domainDir" id="radio-dir" value="DIR"
                                        #domainDir
                                        [checked]="domainDir.value === selectedDomain"
                                        (change)="onDomainChange($event, domainDir.value)"
                                    >
                                    <label class="form-check-label" for="radio-dir">DIR(Production)</label>
                                </div>
                                <div class="form-check-inline form-switch">
                                    <input class="form-check-input form-domain" type="checkbox" formControlName="domainDs" id="radio-ds" value="DS"
                                        #domainDs
                                        [checked]="domainDs.value === selectedDomain"
                                        (change)="onDomainChange($event, domainDs.value)"
                                    >
                                    <label class="form-check-label" for="radio-ds">DS(Stage)</label>
                                </div>

                                <ng-template #domainTooltip>
                                    <div class="custom-tooltip text-center">
                                        Only one domain DIR or DS password can be changed at a time.
                                    </div>
                                </ng-template>
                                <i class="bi bi-info-circle icon-alignment info-element"
                                    placement="auto" [ngbTooltip]="domainTooltip"  triggers="hover click">
                                </i>
                                <div *ngIf="submitted && changePasswordForm.hasError('requiredDomain')" class="text-danger text-danger-format">
                                    Domain is required
                                </div>
                            </div>
                        </div>

                        <!-- Password -->
                        <div class="row mb-2">
                            <label for="password" class="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-form-label col-form-label-xs fw-bold">New Password</label>

                            <div class="col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-7">
                                <input type="password" class="form-control form-control-xs" id="password" formControlName="password" #passwordInput
                                    [ngClass]="{'is-invalid':  submitted && changePasswordForm.controls.password.errors}"
                                    (focus)="onPasswordFocusInput()"
                                    (blur)="ngPasswordLeave()"
                                    (keyup)="onPasswordFocusInput()"
                                    [ngClass]="{'is-invalid':submitted && changePasswordForm.controls.password.invalid}">

                                <ng-template #passwordTooltip>
                                    <div class="custom-tooltip text-center">
                                        Click <a href="https://in.accenture.com/connectivitysecurity/complexity-rules-for-enterprise-password/" target="_blank">here</a> for further guidance on the password complexity rules
                                    </div>
                                </ng-template>
                                <i class="bi bi-info-circle form-element info-element" placement="auto" [ngbTooltip]="passwordTooltip" triggers="mouseenter click"></i>

                                <div *ngIf="submitted && changePasswordForm.controls.password.hasError('required')" class="text-danger text-sm text-danger-format">
                                    Please enter your New Administrative Password.
                                </div>

                                <div *ngIf="passwordClicked">
                                    <div class="text-danger text-sm text-danger-format">
                                        <div [ngClass]="!changePasswordForm.controls.password.hasError('derivedPassword') ? 'bi bi-check-circle-fill flex-shrink-0 mt-2 text-success' : 'bi bi-exclamation-triangle-fill flex-shrink-0 mt-2'">
                                            Not derived from EID
                                        </div>
                                        <div [ngClass]="!changePasswordForm.controls.password.hasError('required') && !changePasswordForm.controls.password.hasError('minlength') ?'bi bi-check-circle-fill flex-shrink-0 mt-2 text-success':'bi bi-exclamation-triangle-fill flex-shrink-0 mt-2'" >
                                            Atleast 8 Characters long 
                                        </div>
                                        <div *ngIf="!isPassphrase(changePasswordForm.controls.password.value)">
                                            <div [ngClass]="!changePasswordForm.controls.password.hasError('atLeastOneUppercaseOrLowercase') ?'bi bi-check-circle-fill flex-shrink-0 mt-2 text-success':'bi bi-exclamation-triangle-fill flex-shrink-0 mt-2'" > 
                                                1 Uppercase or Lowercase 
                                            </div>
                                            <div [ngClass]="!changePasswordForm.controls.password.hasError('atLeastOneNumber') ?'bi bi-check-circle-fill flex-shrink-0 mt-2 text-success':'bi bi-exclamation-triangle-fill flex-shrink-0 mt-2'" > 
                                                1 Numeral
                                            </div>
                                            <div [ngClass]="!changePasswordForm.controls.password.hasError('atLeastOneSpecialCharacter') ?'bi bi-check-circle-fill flex-shrink-0 mt-2 text-success':'bi bi-exclamation-triangle-fill flex-shrink-0 mt-2'" > 
                                                1 Special Character <br> (e.g., &#64;#$%, excluding non- anglicized characters, such as é, ö, Ø, ñ, å)
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row mb-2">
                            <label for="confirm-password" class="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-form-label col-form-label-xs fw-bold">Confirm Password</label>
                            <div class="col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-7" >
                                <input type="password" class="form-control form-control-xs" id="confirm-password" formControlName="confirm_password"
                                    [ngClass]="{'is-invalid':changePasswordForm.hasError('mismatchedPasswords') ||
                                                submitted && ( changePasswordForm.controls.confirm_password.errors)}"
                                >
                                <div *ngIf="submitted && changePasswordForm.controls.confirm_password.hasError('required')" class="text-danger text-sm text-danger-format">
                                    Please re-enter New Administrative Password.
                                </div>
                                <div *ngIf="changePasswordForm.hasError('mismatchedPasswords')" class="text-danger text-sm text-danger-format">
                                    The new passwords typed in do not match.
                                    <br>Please check your new passwords and try again.
                                </div>
                                <div *ngIf="!changePasswordForm.hasError('mismatchedPasswords') && changePasswordForm.controls.password.value != '' && changePasswordForm.controls.confirm_password.value != ''" class="text-success text-sm text-danger-format">
                                    Passwords match.
                                </div>
                            </div>
                        </div>

                        <!-- Submit button -->
                        <div class="row mb-1">
                            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-form-label col-form-label-xs fw-bold"></div>
                            <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                <button type="submit" class="btn btn-purple btn-sm px-2" id="changepassbtn" (click) = "error()" [disabled]="changePasswordForm.invalid || submitted">
                                <span [ngClass]="{'spinner-border': loadingSubmit}" role="status"></span>    
                                    Change Password
                                </button>
                                <div *ngIf="response">
                                    <p *ngIf="isAccountUnknown" class="text-danger text-sm text-danger-format">
                                        Unknown Account Name.
                                    </p> 
                                </div>
                                <div *ngIf="
                                    (
                                        passwordClicked && 
                                        submitted
                                    ) && (
                                        changePasswordForm.controls.password.hasError('minLength') || 
                                        changePasswordForm.controls.password.hasError('atLeastOneSpecialCharacter') || 
                                        changePasswordForm.controls.password.hasError('atLeastOneUppercaseOrLowercase') || 
                                        changePasswordForm.controls.password.hasError('atLeastOneNumber')
                                    )"
                                    class="text-danger text-sm text-danger-format" style="line-height: 18px;"
                                >
                                    {{errormessage}}
                                </div>

                            </div>
                        </div>

                    </form>
                </div>
                <div *ngIf="submit" [innerHTML]="submit.message" [ngClass]="submit.class" class="text-sm text-center" style="font-size:10px;line-height:20px;">
                    <!-- {{submit.message}} -->
                </div>
                <div *ngIf="!acpEmailFailed" class="text-danger text-sm text-center" style="font-size:10px;">{{submit.failMessage}}</div>
                <!-- Cancel Modal -->
                <div class="modal" id="myModal">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                You have not changed your Administrative Account Password. To review Policy 57 Information Security and ensure you are in compliance, navigate to <a href="https://policies.accenture.com " target="_blank" class="nounderline"> https://policies.accenture.com</a>.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" (click)="closeModal()" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<br>
<app-footer></app-footer>

<div id="acn-modal" data-widgetid="2288" data-appid="610"></div>
